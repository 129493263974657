import { env as env2 } from '@rsmus/react-env-image'

/*
    The following are values found in either the .env files or in the environment variables on the machine.
    Environment variables from the machine are preferred, but .env files can be useful in development.  In such
    cases, use .env.development, since that will not propagate to the production environment.
*/

// This is a temporary work around to allow tests to succeed.  The current env function will throw
//  errors if the window object doesn't have a config property.  The offending NPM package should be
//  updated soon, and this if-block can be removed at that time.
let env = env2
if ((window as any) != null && (window as any).config == null) {
  env = function () {
    return undefined
  }
}

export const REACT_APP_BASE_API_URL = env(
  'REACT_APP_BASE_API_URL'
)