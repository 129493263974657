import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { GetSiteDBDetails } from "../../../api-client-nswag/taxportal-client";
import { selector, useRecoilValue } from "recoil";
import {
  getSiteCreationResponse,
  getSiteExistResponse,
} from "../SitesWithLegalEntities";

const isSiteSiteExistSelector = selector({
  key: "getSiteCreationResponse",
  get: ({ get }) => {
    const text = get(getSiteCreationResponse);
    return text;
  },
});
const getSiteExistResponseSelector = selector({
  key: "getSiteExistResponse",
  get: ({ get }) => {
    const text = get(getSiteExistResponse);
    return text;
  },
});

const CustomizeSiteNameForIS = (props: any) => {
  const [text, setText] = useState("");
  //const [siteData, setSiteData] = useState<GetSiteDBDetails[]>([]);

  const onChange = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[ A-Za-z0-9_.-]*$/;
    if (regex.test(inputValue)) {
      setText(inputValue);
      props.setIsFlag(false);
    }
  };

  useEffect(() => {
    props.setsiteNameForIS(text);
  }, [text]);

  useEffect(() => {
    props.setChecked(true)
    // const fetch = async () => {
    //   const data = await taxPortalClientApi.highQ_GetSiteFromTrack(null, null);
    //   setSiteData(data);
    // };
    // fetch();
  }, []);
  const message = useRecoilValue(getSiteExistResponseSelector);
  const flag = useRecoilValue(isSiteSiteExistSelector);
  return (
    <>
      <div>Instructions...</div>
      <div>
        <ul>
          <li>
            {
              "No special characters can be used except (Hyphen (-), Dot(.) and Underscore ( _ ))"
            }
          </li>
          <li>Duplicate site names are not allowed.</li>
          <li>
            {
              "Site name should not be exceeded 66 characters (Should be <= 66, including client ID.)"
            }
          </li>
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          margin: "16px 0 0 0",
        }}
      >
        <TextField
          inputProps={{ maxLength: 56 }}
          id="outlined-basic"
          variant="outlined"
          style={{ width: "30%" }}
          onChange={onChange}
          value={text}
        />
        <div style={{ fontWeight: "bold", color: "#009CDE", marginLeft: "16px", fontSize: "14px" }}>
          {66 - text.length} characters left
        </div>
      </div>
      {flag === true ? (
        <div style={{ color: "red", fontSize: "14px" }}>{message}</div>
      ) : null}
    </>
  );
};

export default CustomizeSiteNameForIS;
