import { TreeView } from "devextreme-react";
import { TreeViewProps } from "./TreeViewTypes";

import "./TreeView.scss";

export const Tree: React.FC<TreeViewProps> = (treeProps) => {
  const selected = treeProps.selectedCounter ? treeProps.selectedCounter : false;
  return (
    <>
      <div className="form">
        <div className="tree-header">
          <h2 className="cell__tree--title hq-title">{treeProps.title}</h2>
          <h2
            className={`cell ${selected && Number(selected) > 0 ? "cell__counter hq-title" : "cell--hide"}`}
          >{`${selected} selected`}</h2>
        </div>
        <TreeView {...treeProps} ref={treeProps.reference} />
      </div>
    </>
  );
};
