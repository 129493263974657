import { useEffect, useState } from "react";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { HQSiteDetailsDTO } from "../../api-client-nswag/taxportal-client";

import useSnackbar from "../../hooks/useSnackbar";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { defaultPageSize } from "../../constants";
import { SitesSelected } from "../../cui/components/ManageSitesComponents/SitesSelected";
import { SitesGrid } from "../../cui/components/ManageSitesComponents/SitesGrid";
import { constants, upgradeSites } from "./UpgradeSites.types";

const initialStateData = { pending: true, sites: [], totalCount: 0 };
const { PAGE_TITLE } = constants;

export const UpgradeSites = () => {
  const { showSnackbar } = useSnackbar();
  const [sitesData, setSitesData] = useState<upgradeSites>(initialStateData);
  const [selectedSites, setSelectedSites] = useState<number[]>([]);
  const [selectedSitesData, setSelectedSitesData] = useState<HQSiteDetailsDTO[]>([]);
  const [dropDown, setDropDown] = useState<string>("");
  const [hideSelectedGrid, setHideSelectedGrid] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const toggleSitesGrids = (hideSelected: boolean) => {
    setHideSelectedGrid(hideSelected);
  };

  const fetchData = async () => {
    setSitesData((prevData: upgradeSites) => {
      return { ...prevData, pending: true, sites: [] };
    });
    try {
      const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      setSitesData(initialStateData);
      const { sitesUpgradeList, totalCount } = await taxPortalClientApi.highQ_GetSitesUpgradeByStatus(
        null,
        null,
        startIndex,
        defaultPageSize
      );
      setSitesData({ pending: false, sites: sitesUpgradeList, totalCount });
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const fetchSitesBySearchTerms = async () => {
    setSitesData({ pending: true, sites: [], totalCount: 0 });
    try {
      const { sitesUpgradeList, totalCount } = await taxPortalClientApi.highQ_GetSitesUpgradeBySearch(
        null,
        null,
        searchTerm
      );
      if (sitesUpgradeList.length) {
        setSitesData({ pending: false, sites: sitesUpgradeList, totalCount });
      } else {
        setSitesData({ ...initialStateData, pending: false });
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      setSitesData(initialStateData);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      fetchSitesBySearchTerms();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (hideSelectedGrid && !searchTerm) fetchData();
  }, [hideSelectedGrid, pageNumber, searchTerm]);

  return (
    <div style={{ margin: "16px" }}>
      <h2>{PAGE_TITLE}</h2>
      {hideSelectedGrid ? (
        <SitesGrid
          sitesData={sitesData}
          selectedSites={selectedSites}
          setSelectedSites={setSelectedSites}
          setSelectedSitesData={setSelectedSitesData}
          dropDown={dropDown}
          setDropDown={setDropDown}
          fetchData={fetchData}
          toggleSitesGrids={toggleSitesGrids}
          setPageNumber={setPageNumber}
          setSearchTerm={setSearchTerm}
        />
      ) : (
        <SitesSelected
          selectedSitesData={selectedSitesData}
          selectedSites={selectedSites}
          setSelectedSites={setSelectedSites}
          templateSelected={dropDown}
          toggleSitesGrids={toggleSitesGrids}
        />
      )}
    </div>
  );
};
