import { useContext, useEffect, useState } from "react";
import { models, Report, Embed } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { PowerBIReport, ServiceBusEnum, ServiceMessage } from "../../api-client-nswag/taxportal-client";
import SyncIcon from "@mui/icons-material/Sync";
import "./PowerBIReport.scss";
import { OidcIdentityContext } from "@rsmus/react-auth/dist/OidcIdentity";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarState } from "../../cui/commonComponents/CustomSnackbar";
import { externalClients } from "../../App";
import { FlagType } from "./PowerBIReportIM";
import UnAuthPowerBi from "../../cui/commonComponents/UnAuthPowerBi";
import { getNewAccessToken, timeOutForRefreshToken } from "../../util/Utils";
import { userPermissions } from "../../App";

export const PowerBISiteIM = () => {
  const [reportIM, setReportIM] = useState<Report | null>(null);
  const [filtersIM, setFiltersIM] = useState<any[]>([]);
  const [report, setReport] = useState<Report | null>(null);
  const [filters, setFilters] = useState<any[]>([]);
  const [tokenTimeout, setTokenTimeout] = useState<any>(null);
  const [tokenExpiration, setTokenExpiration] = useState<Date | null>(null);
  var siteID = parseInt(sessionStorage.getItem("siteId")!);
  const setSnackbar = useSetRecoilState(snackbarState);
  const { user, logout } = useContext(OidcIdentityContext);
  const cemClients = useRecoilValue(externalClients);
  const [flag, setFlag] = useState<FlagType>({
    ps: null,
    ir: null,
  });
  const userPermission = useRecoilValue(userPermissions);

  const [reportConfigDTOIM, setReportConfigIM] = useState<models.IReportEmbedConfiguration>({
    type: "report",
    embedUrl: "",
    tokenType: models.TokenType.Embed,
    accessToken: "",
    settings: undefined,
  });

  const [reportConfigDTO, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: "report",
    embedUrl: "",
    tokenType: models.TokenType.Embed,
    accessToken: "",
    settings: undefined,
  });

  useEffect(() => {
    if (siteID === null) {
      if (cemClients?.find((x) => x.siteId)?.siteId !== 0) {
        const id = cemClients?.find((x) => x.siteId)?.siteId!;
        if (id !== undefined) siteID = id;
      }
    }
  }, [cemClients, document.referrer]);

  useEffect(() => {
    loadReportConfig();
    loadReportConfigIM();
  }, [siteID]);

  useEffect(() => {
    if (siteID !== null) {
      //Filter for IM
      const filteredBySiteIdIM = basicfilterSchema("HQSiteIDs", "HQSiteID", "In", `${siteID}`);
      const filteredByUserIdIM = basicfilterSchema(
        "HQUserClientAccess",
        "UserId",
        "In",
        `${user?.profile.rsmuid as string}`
      );
      const combinedIM = [filteredBySiteIdIM, filteredByUserIdIM];
      setFiltersIM(combinedIM);

      //Filter for PS
      const filteredBySiteIdPS = basicfilterSchema("HQSiteIDs", "HQSiteid", "In", `${siteID}`);
      const filteredByUserIdPS = basicfilterSchema(
        "vw_UserProjectStatus",
        "UserId",
        "In",
        `${user?.profile.rsmuid as string}`
      );
      const combinedPS = [filteredBySiteIdPS, filteredByUserIdPS];
      setFilters(combinedPS);
    }
  }, [siteID]);

  const refreshAccessToken = async () => {
    if (reportIM) {
      const res = await getNewAccessToken(PowerBIReport.TP_INFORMATION_REQUEST);
      if (res && res?.token !== null) {
        const newToken = res.token;
        reportIM.setAccessToken(newToken as string);

        const expiresIn = new Date();
        expiresIn.setHours(expiresIn.getHours() + 1);
        setTokenExpiration(expiresIn);

        if (tokenTimeout) {
          clearTimeout(tokenTimeout);
        }

        const currentTime = new Date();
        const refreshTime = expiresIn.getTime() - currentTime.getTime() - 2 * 60 * 1000;
        if (refreshTime > 0) {
          setTokenTimeout(setTimeout(refreshAccessToken, refreshTime));
        }
      }
    } else {
      console.error("Failed to refresh token for reportIM.");
    }
    if (report) {
      const res = await getNewAccessToken(PowerBIReport.TP_INFORMATION_REQUEST);
      if (res && res?.token !== null) {
        const newToken = res.token;
        report.setAccessToken(newToken as string);

        const expiresIn = new Date();
        expiresIn.setHours(expiresIn.getHours() + 1);
        setTokenExpiration(expiresIn);

        if (tokenTimeout) {
          clearTimeout(tokenTimeout);
        }

        const currentTime = new Date();
        const refreshTime = expiresIn.getTime() - currentTime.getTime() - 2 * 60 * 1000;
        if (refreshTime > 0) {
          setTokenTimeout(setTimeout(refreshAccessToken, refreshTime));
        }
      }
    } else {
      console.error("Failed to refresh token.");
    }
  };

  useEffect(() => {
    timeOutForRefreshToken(tokenExpiration, setTokenTimeout, refreshAccessToken);
    return () => {
      if (tokenTimeout) {
        clearTimeout(tokenTimeout);
      }
    };
  }, [reportIM, report, tokenExpiration]);

  const loadReportConfigIM = async () => {
    if (siteID !== null) {
      await taxPortalClientApi
        .reports_GetEmbedReport(PowerBIReport.TP_INFORMATION_REQUEST, userPermission)
        .then((res) => {
          const settings = {
            type: "report",
            embedUrl: res?.embedUrl,
            accessToken: res?.token,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
            },
          };

          setReportConfigIM(settings);
          setFlag((prevState) => ({ ...prevState, ir: false }));
          const expiresIn = new Date(res.expiration as Date);
          expiresIn.setMinutes(expiresIn.getMinutes() - 2);
          setTokenExpiration(expiresIn);
        })
        .catch((err) => {
          if (err.status === 401) {
            setFlag((prevState) => ({ ...prevState, ir: true }));
          }
        });
    }
  };

  const loadReportConfig = async () => {
    if (siteID !== null) {
      await taxPortalClientApi
        .reports_GetEmbedReport(PowerBIReport.TP_PROJECT_STATUS, userPermission)
        .then((res) => {
          const settings = {
            type: "report",
            embedUrl: res?.embedUrl,
            accessToken: res?.token,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
            },
          };

          setReportConfig(settings);
          setFlag((prevState) => ({ ...prevState, ps: false }));
          const expiresIn = new Date(res.expiration as Date);
          expiresIn.setMinutes(expiresIn.getMinutes() - 2);
          setTokenExpiration(expiresIn);
        })
        .catch((err) => {
          setFlag((prevState) => ({ ...prevState, ps: true }));
        });
    }
  };

  const basicfilterSchema = (tableName: string, columnName: string, operator: string, field: unknown | null) => {
    const schema = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: tableName,
        column: columnName,
      },
      filterType: models.FilterType.Basic,
      operator: operator,
      values: [field],
      requireSingleSelection: true,
    };
    return schema;
  };

  useEffect(() => {
    if (flag.ir !== null && flag.ir === false) {
      if (reportIM && filtersIM.length > 0) {
        const onLoadedHandler = () => {
          reportIM
            .setFilters(filtersIM)
            .then(() => console.log("Filters applied successfully."))
            .catch(() => console.log("Failed to apply filters."));
        };
        reportIM.off("loaded", onLoadedHandler);
        reportIM.on("loaded", onLoadedHandler);
      }
    }
    if (flag.ps !== null && flag.ps === false) {
      if (report && filters.length > 0) {
        const onLoadedHandler = () => {
          report
            .setFilters(filters)
            .then(() => console.log("Filters applied successfully."))
            .catch(() => console.log("Failed to apply filters."));
        };
        report.off("loaded", onLoadedHandler);
        report.on("loaded", onLoadedHandler);
      }
    }
  }, [reportIM, report, filters, filtersIM, flag.ir, flag.ps]);

  const syncADIconClick = async () => {
    try {
      if (siteID !== null) {
        const siteData: ServiceMessage[] = [
          {
            siteId: siteID,
            eventType: ServiceBusEnum.SYNC_AD,
            userName: user?.profile.preferred_username,
          },
        ];
        const res = await taxPortalClientApi.taxPortal_InsertTopic(siteData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const syncIMIconClick = async () => {
    try {
      await syncADIconClick();
      let selectedSyncData: ServiceMessage[] = [];
      if (siteID !== null) {
        const siteData = [
          {
            siteId: siteID,
            eventType: ServiceBusEnum.SYNC_IR,
            userName: user?.profile.preferred_username,
          },
        ];
        selectedSyncData = siteData;
      }

      setSnackbar({
        open: true,
        message: "IR data synchronization in progress",
        severity: "info",
      });
      const res = await taxPortalClientApi.taxPortal_InsertTopic(selectedSyncData);
    } catch (error) {
      console.log(error);
    }
    if (reportIM) {
      setTimeout(async () => {
        await reportIM.refresh();
      }, 2000);
    }
  };

  const syncPSIconClick = async () => {
    try {
      await syncADIconClick();
      let selectedSyncData: ServiceMessage[] = [];
      if (siteID !== null) {
        const siteData = [
          {
            siteId: siteID,
            eventType: ServiceBusEnum.SYNC_PS,
            userName: user?.profile.preferred_username,
          },
        ];
        selectedSyncData = siteData;
      }

      setSnackbar({
        open: true,
        message: "PS data synchronization in progress",
        severity: "info",
      });
      const res = await taxPortalClientApi.taxPortal_InsertTopic(selectedSyncData);
    } catch (error) {
      console.log(error);
    }

    if (report) {
      setTimeout(async () => {
        await report.refresh();
      }, 2000);
    }
  };

  return (
    <div className="powerBi-container">
      <div className="outerDiv">
        {flag.ir === true ? (
          <UnAuthPowerBi name={"IR"} />
        ) : (
          <div className="leftDiv">
            <PowerBIEmbed
              embedConfig={reportConfigDTOIM}
              cssClassName="report-style-class"
              getEmbeddedComponent={(embedObject: Embed) => {
                setReportIM(embedObject as Report);
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 5,
                left: "40%",
                cursor: "pointer",
              }}
            >
              {reportConfigDTOIM.settings !== undefined && (
                <SyncIcon onClick={syncIMIconClick} style={{ color: "#009cde" }} />
              )}
            </div>
          </div>
        )}
        {flag.ps === true ? (
          <UnAuthPowerBi name={"PS"} />
        ) : (
          <div className="rightDiv">
            <PowerBIEmbed
              embedConfig={reportConfigDTO}
              cssClassName="report-style-class"
              getEmbeddedComponent={(embedObject: Embed) => {
                setReport(embedObject as Report);
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 5,
                right: 10,
                cursor: "pointer",
              }}
            >
              {reportConfigDTO.settings !== undefined && (
                <SyncIcon onClick={syncPSIconClick} style={{ color: "#009cde" }} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
