import { HQSiteDetailsDTO } from "../../api-client-nswag/taxportal-client";

export interface upgradeSites {
  pending: boolean;
  sites: HQSiteDetailsDTO[];
  totalCount: number;
}

export const constants = {
  ACTIVE: "ACTIVE",
  PAGE_TITLE: "Upgrade Sites",
};
