import { snackbarState } from "../cui/commonComponents/CustomSnackbar";
import { useSetRecoilState } from "recoil";
import { showSnackbarType } from "./useSnackbar.types";

const useSnackbar = () => {
  const setSnackbar = useSetRecoilState(snackbarState);

  const showSnackbar = ({ open = true, message, severity = "info" }: showSnackbarType) => {
    setSnackbar({
      open,
      message,
      severity,
    });
  };

  const showSuccessSnackbar = ({ open = true, message, severity = "success" }: showSnackbarType) => {
    setSnackbar({
      open,
      message,
      severity,
    });
  };

  return { showSnackbar, showSuccessSnackbar };
};

export default useSnackbar;
