import React, { useEffect, useMemo, useState } from "react";
import { NavigationBar } from "../../navigation/NavigationBar";
import { Outlet } from "react-router-dom";
import { SharedLayoutProps } from "./SharedLayout.types";
import rsmLogo from "../../../assets/img/rsm-logo.png";
import { NavigationMenu, NavigationMenuSingleObject } from "../../navigation/NavigationBar.types";
import SharedLayoutContext from "./SharedLayout.context";
import { Footer } from "@rsmus/rsm-catt-ui";
import CustomSnackbar from "../../../commonComponents/CustomSnackbar";
import { homeNavigationMenuData } from "../../../../pages/home/Home.data";

export const SharedLayout: React.FunctionComponent<SharedLayoutProps> = (props) => {
  const [isNavActive, setIsNavActive] = useState(false);
  const [isScrolled, setScrolled] = useState(false);

  const [navigationMenuButtons, setNavigationMenuButtons] = useState<NavigationMenu[]>([]);
  const navigationMenuButtonsValue = useMemo(
    () => ({
      navigationMenuButtons,
      setNavigationMenuButtons,
    }),
    [navigationMenuButtons]
  );

  const homeButton = {
    icon: "home",
    name: "Home",
    redirectUrl: "/",
    tooltip: "",
  } as NavigationMenuSingleObject;

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <SharedLayoutContext.Provider value={navigationMenuButtonsValue}>
      <CustomSnackbar />
      <>
        {(props.hqSiteId === undefined && props.hqSiteId === null) || props.flag === false ? (
          <div
            id="cui-layout"
            cui-layout-dx={props.maxWidth}
            className={`${props.isNavigationBarEnabled && (isNavActive ? "layout-expanded" : "layout-collapsed")} ${
              isScrolled && "layout-scrolled"
            }`}
          >
            {props.isNavigationBarEnabled && (
              <NavigationBar
                home={homeButton}
                menu={homeNavigationMenuData}
                applicationTitle={props.applicationTitle}
                isNavigationBarEnabled={props.isNavigationBarEnabled}
                isNavActive={isNavActive}
                setIsNavActive={setIsNavActive}
                enableSearch={true}
                enableAccordion={true}
              />
            )}
            <div id="cui-layout_main">
              <main className="cui-page">
                <div className="cui-page_wrapper">
                  <Outlet />
                </div>
              </main>
              <Footer
                imgUrl={rsmLogo}
                footerRedirectPages={[
                  { url: "/", text: "Terms of Use" },
                  { url: "/", text: "Privacy" },
                  { url: "/", text: "Contact" },
                  { url: "/", text: "Cookies" },
                ]}
                tradeRightsYear={"2023"}
              />
            </div>
          </div>
        ) : (
          <div style={{ width: "100%", height: "100%", background: "#fff" }}>
            <Outlet />
          </div>
        )}
      </>
    </SharedLayoutContext.Provider>
  );
};
export default SharedLayout;
