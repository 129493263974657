import React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";

export enum Theme {
	GREEN = 'green',
	BLUE = 'blue',
	ORANGE = 'orange',
	DEFAULT = 'default'
}

export type CattThemeProps = {
	theme: Theme;
	children: React.ReactNode
}

export const CattThemeProvider: React.FC<CattThemeProps> = (props) => {
	const greenStyle = (
		<style>
			{`
		      :root {
		        --cui-color-primary: #3F9C35;     
		        --cui-color-primary-rgb:  63, 156, 53;
		        --cui-color-ico-base: 63, 156, 53;
		        --cui-color-ico-light: 108, 175, 93;
		        
		      }
            `}
		</style>
	);
	const orangeStyle = (
		<style>
			{`
		      :root {
		        --cui-color-primary: #E87722;     
		        --cui-color-primary-rgb:  232, 119, 34;
		        --cui-color-ico-base:  232, 119, 34;
		        --cui-color-ico-light: 236, 146, 78;
		      }
	        `}
		</style>
	);

	let themeColor;
	let styleComponent;
	switch(props.theme){
		case Theme.GREEN:
			themeColor = "#3F9C35";
			styleComponent = greenStyle;
			break;
		case Theme.BLUE:
			themeColor = "#009CDE";
			break;
		case Theme.ORANGE:
			themeColor = "#E87722";
			styleComponent = orangeStyle;
			break;
		default:
			themeColor = "#009CDE";
			break;
	}


	const theme = createTheme({
		palette: {
			primary: {
				main: themeColor,
			},
		},
		typography: {
			fontFamily: [
				"Arial",
				"Roboto",
				'"Helvetica Neue"',
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
		},
	});

	return (
		<ThemeProvider theme={theme}>
			{styleComponent}
			{props.children}
		</ThemeProvider>
	)
}
