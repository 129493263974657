import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ModalProps } from "../Modal/Modal.types";
import "./ModalTaxPortal.scss";

export const ModalTaxPortal = ({
  title = "",
  btnOpenText = "",
  btnCloseText = "Cancel",
  maxWidth = "sm",
  isDisplayModal,
  handleContinue,
  handleClose,
  children,
  isDisabledOpen = false,
}: ModalProps) => {
  return (
    <Dialog
      open={isDisplayModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={maxWidth}
      className="hq-page hq-modal cui-c_modal"
    >
      <DialogTitle id="alert-dialog-title" className="cui-c_modal-title hq-title">
        {title}
      </DialogTitle>
      <DialogContent className="cui-c_modal-body">{children}</DialogContent>
      <DialogActions className="cui-c_modal-footer">
        <Button onClick={handleClose} className="hq_button hq_button-secondary">
          {btnCloseText}
        </Button>
        {!!btnOpenText?.length ? (
          <Button onClick={handleContinue} variant="contained" className="hq_button" disabled={isDisabledOpen}>
            {btnOpenText}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
