import React from 'react'
interface UnAuthPowerBiType{
    name :string
}
const UnAuthPowerBi = (props:UnAuthPowerBiType) => {
  return (
    <div
            style={{
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              height: "80vh",
              float:"left"
            }}
          >
            User is not authorised with {props.name} PowerBi
          </div>
  )
}

export default UnAuthPowerBi