import { Dispatch } from "react";
import { SnackbarMessage, showSnackbarType } from "../../../hooks/useSnackbar.types";
import {
  ActionTypes,
  FETCH_SUCCESS,
  InitialScheduleStateProps,
  SAVING_CANCEL,
  SAVING_ERROR,
  SAVING_SUCCESS,
  SET_EDIT_ROW_KEY,
  SHOW_LOADING,
  UPDATE_CHANGES,
} from "../ScheduleFiles.types";
import { IChangeProps } from "devextreme-react/tree-list";
import { GetScheduleFiles } from "../../../api-client-nswag/taxportal-client";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import moment from "moment";

export const fetchData = async (
  dispatch: Dispatch<ActionTypes>,
  userName: string,
  showSnackbar: ({ message }: showSnackbarType) => void
) => {
  dispatch({ type: SHOW_LOADING });
  try {
    const data: GetScheduleFiles[] = await taxPortalClientApi.taxPortal_GetAllSchedules();
    dispatch({
      type: FETCH_SUCCESS,
      payload: {
        data,
        userName,
      },
    });
  } catch (err) {
    dispatch({ type: SAVING_ERROR });
    showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
  }
};

export const setEditRowKey = (dispatch: Dispatch<ActionTypes>, editRowKey: number | null) => {
  dispatch({
    type: SET_EDIT_ROW_KEY,
    payload: editRowKey,
  });
};

export const updateChanges = (dispatch: Dispatch<ActionTypes>, changes: IChangeProps[]) => {
  dispatch({
    type: UPDATE_CHANGES,
    payload: changes,
  });
};

export const applyChanges = async (
  dispatch: Dispatch<ActionTypes>,
  change: IChangeProps,
  dataSchedules: InitialScheduleStateProps,
  userName: string
) => {
  if (change && change.type) {
    dispatch({ type: SHOW_LOADING });
    try {
      const values = change.data;
      if (values && values.startExecution) values.startExecution = moment(values.startExecution).utc().toDate();
      const updateRow = {
        ...dataSchedules.editRow,
        ...values,
      };
      await taxPortalClientApi.taxPortal_UpdateScheduleLogsForUser(change.key, updateRow, userName);

      dispatch({
        type: SAVING_SUCCESS,
        payload: {
          change,
        },
      });
    } catch (err) {
      dispatch({ type: SAVING_ERROR });
    }
  } else {
    dispatch({ type: SAVING_CANCEL });
    return null;
  }
};
