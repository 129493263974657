import React from "react";

import { createRoot } from "react-dom/client";
import { OidcIdentity } from "@rsmus/react-auth";
import reportWebVitals from "./reportWebVitals";
import { envProperties } from "./util/EnvironmentProperties";
import { RecoilRoot } from "recoil";

import "./cui/sass/cui.theme.scss";
import App from "./App";
import SignOut from "./pages/signOut/SignOut";
const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <OidcIdentity
    authority={envProperties.authentication.authority}
    redirect_uri={window.location.origin}
    client_id={envProperties.authentication.clientId}
    postLogoutRedirectUri={"rsmus.com"}
    automaticSilentRenew={true}
    autoLogin={true}
    redirectAfterLogout={true}
    accessTokenExpiringNotificationTimeInSeconds={180}
  >
    {window.location.href.includes("/signout") ? (
      <SignOut />
    ) : (
      <RecoilRoot>
        <App />
      </RecoilRoot>
    )}
  </OidcIdentity>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
