import { AlertColor } from "@mui/material";

export enum SnackbarMessage {
  CREATE_FOLDER = "Folder Creation in progress",
  SYNC_SITE_SETTINGS_RETRY = "Site settings in progress",
  ERROR = "Something went wrong!",
  UPDATE_FILE = "Update Files in progress",
  SYNC_CEM = "Sync with CEM in progress",
  RETRY_SYNC_CEM = "Retry for Sync with CEM in progress",
  SYNC_PS = "Populating Project Status Started",
  SYNC_IR = "Populating IR Started",
  POPULATE_DM_CHOICES = "Populating Document Metadata Started",
  POPULATE_LEGAL_ENTITIES = "Populating Legal Entities Started",
  POPULATE_THIRD_PARTY_ORG = "Populating Third-party Organization Started",
  POPULATE_CONTROVERSY_TRACKER = "Populating Entities in Controversy Tracker Isheet Started",
  SCHEDULE_PROGRESS = "Schedule Files Updates in progress",
  SCHEDULE_DETAILS = "Loading log details",
  UPDATE_SITE = "Update site in progress",
  REMOVE_MAPPING = "Site Archiving in progress",
  REMOVE_MAPPING_SUCCESS = "Site Archiving successful",
  REMOVE_MAPPING_FAIL = "Site Archiving Failed",
  SYNC_MDM = "Sync with MDM in progress",
  NO_SITE = "Site not found",
  CUSTOM_PERMISSIONS= "Updation of Custom Permissions in progress",
  MOVE_IMANAGE_FILES = "Copying iManage files in progress. You can navigate away from the screen.",
  IMANAGE_FILES_NO_DATA = "There is no data for: %item%",
  IMANAGE_FILES_NO_MODIFY = "The files have not been modified",
  UNARCHIVE_SITE = "Activation of Site in progress",
  ADDCLIENTID_SITENAME = "Addition of ClientId to Site name in progress",
  ADDCLIENTID_ORGEXISTS = "This Orgnaisation name already exists in HighQ",
  SITE_EXISTS = " could not be reactivated. A site with the same name exists.",
  SITE_EXISTS_WITHCLIENTID = " could not be updated. A site with the same name exists.",
  ORGANIZATION_EXISTS_CLIENTID = " could not be updated. An organisation with the same name exists.",
  UNARCHIVE_SUCCESS = "Activation of Site was successful",
  ADDCLIENTID_SUCCESS = "Addition of ClientId to Site name was successful",
  ADDCLIENTID_FAIL = "Addition of ClientId to Site name Failed",
  UNARCHIVE_FAIL = "Site Activation Failed",
  ROLLOVER_FILES = "Getting rollover files.",
  ROLLOVER_STATUS_DETAILS = "Getting rollover status details.",
  ROLLOVER_PROGRESS = "Rollover is in progress.",
  SCHEDULE_ROLLOVER = "Rollover schedule in progress.",
  CANCEL_SCHEDULE = "Cancelling scheduled rollover.",
  SITE_SETTINGS_PROGRESS = "Settings update in progress.",
  SITE_SETTINGS_FIELD_REQUIRED = "This field is required.",
  SITE_SETTING_EXIST = "%item% already exist. You can edit settings directly in the list.",
  SITE_SETTING_CREATED = "Site Setting created.",
  SITE_SETTING_UPDATED = "Site Setting updated.",
  SITE_SETTING_DELETED = "Site Setting deleted.",
  LEGAL_ENTITIES_EDIT_SUCCESS = "Updation of Legal Entities Successful",
  LEGAL_ENTITIES_EDIT_FAIL = "Updation of Legal Entities Failed",
  FOLDER_PERMISSION_UPDATE = "Updation of Folder Permission Successful",
  FOLDER_PERMISSION_UPDATE_FAIL = "Updation of Folder Permission Failed",
  SITE_SETTINGS_EDIT_SUCCESS = "Updation of Site Settings Successful",
  SITE_SETTINGS_EDIT_FAIL = "Updation of Site Settings Failed",
  SITE_NAME_EDIT_SUCCESS = "Updation of Site Name Successful",
  SITE_NAME_EXISTS = " already exists. Please use a different Site Name",
  SITE_NAME_EDIT_FAIL = "Updation of Site Name Failed",
  RETRIEVE_SITE_CHANGES = "Looking for changes detail. It could take a while...",
  PROCEED_TO_UPGRADE = "Procesing information. It could take a while...",
  TRANSITION_PLAN = "Populating Isheet Transition plan",
  DELIVERY_PLAN = "Populating Isheet Delivery plan",
  PROJECT_SETUP_TASK_TEMPLATES = " Populating Isheet Project Setup Template plan",
  ANALYTIC_INSIGHT_SETUP = " Isheet Analytic Insight ",
  RECORD_EXISTS_POPUP = "If you proceed, the system will remove corresponding choice options in the Entity, Project, Phase dropdowns and will apply the following changes accordingly:\n\n-Suppress in Folders - hide associated folders with the selected entities from non-admin roles.\n-Suppress in Information Request - delete choices from the entity dropdown in IR isheet and delete associated projects and phases choices associated with those entities.\n-Suppress in Project Status - delete associated Project Status records with the selected entities and delete choices from the entity dropdown in Project Status isheet and delete associated projects and phases choices associated with those entities.\n\nAre you sure you want to proceed?",
  CREATE_THIRDPARTY_ORGS = "Creation of Third Party Organization is in progress",
  UPDATE_THIRDPARTY_ORGS = "Updation of Third Party Organization is in progress",
  CREATE_THIRDPARTY_ORGS_SUCCESS = "Creation of Third Party Organization is Successful",
  UPDATE_THIRDPARTY_ORGS_SUCCESS = "Updation of Third Party Organization is Successful",
  SITE_SETUP = "Site SetUP in progress",
  EXPORT_TO_EXCEL_FAIL = "Export to excel Failed",
  EXPORT_TO_EXCEL_SUCCESS = "Successfully exported data to excel",
  IDM_AND_CEM = "Adding External User in Progress.",
  EX_IDM_AND_CEM = "Adding Third-Party User in Progress.",
  ASSIGN_USER_TO_SITE = "Assign User to site in Progress.",
  CHECK_EXTERNAL_USER_STATUS = "Check Status in Progress.",
  DOWNLOAD_MESSAGE = "Downloading file.",
  DOWNLOAD_FAILED = "Info from site %site% couldn't be downloaded.",
  ZIP_DOWNLOAD_FAILED = "The info couldn't be downloaded.",
  COMPARISON_EXCEL_DOWNLOAD_MESSAGE = "This process will download all the changes from selected sites.",
  ZIP_UPGRADE_DOWNLOAD_MESSAGE = "This process will download data only for sites already upgraded.",
  PROVIDE_APP_ACCESS = "Provide Site Role access in Progress.",
  RETRY_FOR_SITE_CREATION = "Retry for Site Creation Started.",
  PROVIDE_CONTENT_ADMIN_ACCESS = "Provide Content Admin access in Progress."
}

export interface showSnackbarType {
  open?: boolean;
  message: SnackbarMessage;
  severity?: AlertColor;
}
