import { Card, CardContent, Container, Typography } from "@mui/material";

import { ChangesCardProps, constants } from "../UpgradeSitesGrids.types";
import { GridSelectChangesData } from "./GridSelectChangesData";

import "../UpgradeSites.scss";

const { STATUSES } = constants;
const { ERROR, PARTIALLY_SUCCESS } = STATUSES;

export const ChangesCard = ({
  selectedChangesDetails,
  isReviewOnly,
  selectedDetails,
  setSelectedDetails,
  setDisabledChanges,
  upgradedDetails,
  prevDataDetails,
  parentComponent,
}: ChangesCardProps) => {
  const { module, detailSelected, status } = selectedChangesDetails;
  const { change, details, partialDetails } = detailSelected!;

  const renderReviewDetails = (details: string[], status: string) => {
    return details.map((value, idx: number) => (
      <li key={`${status}${value}-${idx}`} className="list-item">
        <span className="cell">{value}</span>
        <span className="cell status">{status}</span>
      </li>
    ));
  };

  return (
    <>
      <Container maxWidth="lg" className="card-container">
        <Card sx={{ minWidth: 275, background: "transparent", boxShadow: "none" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              {module}
            </Typography>
            {isReviewOnly ? (
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {change}
              </Typography>
            ) : null}
            <div>
              <div className="changeDetailsContainer">
                {isReviewOnly ? (
                  <ul className="status-list">
                    {status!.toLowerCase() !== PARTIALLY_SUCCESS.toLowerCase()
                      ? renderReviewDetails(details!, status!)
                      : null}
                    {status!.toLowerCase() === PARTIALLY_SUCCESS.toLowerCase()
                      ? renderReviewDetails(partialDetails!, ERROR)
                      : null}
                  </ul>
                ) : (
                  <GridSelectChangesData
                    selectedChangesDetails={selectedChangesDetails}
                    selectedDetails={selectedDetails!}
                    setSelectedDetails={setSelectedDetails!}
                    setDisabledChanges={setDisabledChanges!}
                    upgradedDetails={upgradedDetails}
                    prevDataDetails={prevDataDetails}
                    parentComponent={parentComponent}
                  />
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
