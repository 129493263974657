import { useRef, useState } from "react";
import { Typography, IconButton } from "@mui/material";
import { Grid, TextField, Box } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { ContentCopy, Edit, RemoveCircleOutlineOutlined, InsertDriveFileOutlined } from "@mui/icons-material";

import { ModalCopyProps } from "./ModalCopyFile.types";
import { ModalTaxPortalThree } from "../ModalTaxPortal/ModalTaxPortalThree";
import { MoveIManageFiles } from "../../../api-client-nswag/taxportal-client";
import { EditFileState } from "../../../pages/IManageFiles/IManageFilesTypes";
import { SnackbarMessage, useSnackbar } from "../../../hooks";

import { getExtension } from "../../../util/Utils";
import { iconSelected, parseNewName, updatedState } from "./ModalCopy.utils";

export function ModalCopyFiles({
  filesInspection,
  setFilesInspection,
  isDisplayModal,
  setIsDisplayModal,
  itemEdited,
  setItemEdited,
  onHandleService,
  handleCloseModal,
  isBlurEnabled,
  setIsBlurEnabled,
}: ModalCopyProps) {
  const { showSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [editingFile, setEditingFile] = useState<EditFileState | null>();
  const [isCheckDisabled, setIsCheckDisabled] = useState(false);

  const removeItem = (file: boolean, id: number) => {
    if (file) {
      const removeDocumentId = itemEdited.filter((item) => item !== id);
      setItemEdited(removeDocumentId);
    }
  };

  const handleSkipAll = () => {
    if (!filesInspection!.copy.length) {
      showSnackbar({ message: SnackbarMessage.IMANAGE_FILES_NO_MODIFY });
      handleCloseModal();
      return;
    }
    setIsDisplayModal(false);
    onHandleService(filesInspection!.copy);
  };

  const handleOverwriteAll = () => {
    const allFilesToOverwrite = filesInspection?.duplicated!.map((file: MoveIManageFiles) => ({
      ...file,
      overWrite: true,
    }));
    const filesToOverwrite = [allFilesToOverwrite!, filesInspection!.copy];

    onHandleService(filesToOverwrite.flat());
    handleCloseModal();
  };

  const handleEdit = (file: MoveIManageFiles) => {
    if (isBlurEnabled) {
      inputRef.current?.focus();
      return;
    }
    setIsCheckDisabled(true);

    setFilesInspection((prevState) => updatedState(file, prevState, isBlurEnabled));

    if (!isBlurEnabled && file.isEdited!) {
      setFilesInspection((prevState) => updatedState(file, prevState, isBlurEnabled));

      removeItem(file.isEdited!, file.documentId);
      return;
    }

    setIsBlurEnabled(true);
    setEditingFile({
      id: file.documentId,
      newFileName: file?.nameWithoutExtension!,
    });
  };

  const handleBlur = (file: MoveIManageFiles) => {
    setIsBlurEnabled(false);
    setItemEdited((prev) => [...prev, file.documentId]);

    const extension = getExtension(file.fileName!);
    const fileEdited = editingFile!.newFileName;
    const newName = fileEdited || file.nameWithoutExtension;

    setFilesInspection((prevState) => ({
      ...prevState,
      duplicated: prevState?.duplicated!.map((item) =>
        item.documentId === file.documentId
          ? {
              ...item,
              newFileName: newName!.concat(`.${extension}`),
              overWrite: false,
              isEdited: true,
              isOmitted: false,
            }
          : item
      ),
    }));
  };

  const handelCancelEdit = (file: MoveIManageFiles) => {
    setFilesInspection((prevState) => ({
      ...prevState,
      duplicated: prevState?.duplicated!.map((item) =>
        item.documentId === file.documentId ? { ...item, overWrite: false, isEdited: false, isOmitted: false } : item
      ),
    }));
    removeItem(file.isEdited!, file.documentId);
    setIsBlurEnabled(false);
  };

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanValue = event.target.value.replace(/[^0-9a-zA-Z()\-_.{}[\] ]/g, "");
    setIsCheckDisabled(!cleanValue);
    setEditingFile((current) => (current ? { ...current, newFileName: cleanValue } : null));
  };

  const handleOverwrite = (file: MoveIManageFiles) => {
    if (isBlurEnabled) return;
    setItemEdited((prev) => [...prev, file.documentId]);
    setEditingFile({
      id: file.documentId,
      newFileName: file?.nameWithoutExtension!,
    });
    removeItem(file.overWrite!, file.documentId);

    setFilesInspection((prevState) => ({
      ...prevState,
      duplicated: prevState?.duplicated!.map((item) =>
        item.documentId === file.documentId
          ? { ...item, newFileName: "", overWrite: !item.overWrite, isEdited: false, isOmitted: false }
          : item
      ),
    }));
  };

  const handleOmit = (file: MoveIManageFiles) => {
    if (isBlurEnabled) return;
    setItemEdited((prev) => [...prev, file.documentId]);
    setEditingFile({
      id: file.documentId,
      newFileName: file?.nameWithoutExtension!,
    });
    removeItem(file.isOmitted!, file.documentId);

    setFilesInspection((prevState) => ({
      ...prevState,
      duplicated: prevState?.duplicated!.map((item) =>
        item.documentId === file.documentId
          ? { ...item, newFileName: "", overWrite: false, isEdited: false, isOmitted: !item.isOmitted }
          : item
      ),
    }));
  };

  const isItemEdited = !!itemEdited.length;
  const isButtonDisabled = isItemEdited || isBlurEnabled;
  const documentIds = filesInspection?.duplicated!.map((item) => item.documentId);
  const isAlllItemsModified = documentIds?.every((documentId) => itemEdited.includes(documentId));
  const isButtonSubmitDisabled = !isAlllItemsModified || isBlurEnabled;

  const handleFilesEdited = () => {
    const { duplicated, copy } = filesInspection!;
    const fileToKeep = duplicated.filter((item) => item.isOmitted === false);
    const filesToMove = [copy, fileToKeep].flat();

    if (!filesToMove.length) {
      showSnackbar({ message: SnackbarMessage.IMANAGE_FILES_NO_MODIFY });
      handleCloseModal();
      return;
    }

    const resetNewFilesName = filesInspection?.duplicated!.map((file: MoveIManageFiles) => ({
      ...file,
      newFileName: "",
      overWrite: false,
      isEdited: false,
      isOmitted: false,
    }));

    onHandleService(filesToMove).then(() => {
      setFilesInspection({
        all: resetNewFilesName,
        duplicated: resetNewFilesName,
      });
    });
    handleCloseModal();
  };

  return (
    <>
      <ModalTaxPortalThree
        title="The following files already exist in Tax Portal. You can make individual selections or apply actions in bulk. How would you like to proceed?"
        maxWidth="md"
        firstBtnText="Add as a new version"
        secondBtnText="Keep all existing files"
        thirdButtonText="Apply selections"
        isDisplayModal={isDisplayModal}
        handleFirstBtn={handleOverwriteAll}
        handleSecondBtn={handleSkipAll}
        handleTrirdBtn={handleFilesEdited}
        isDisabledOne={isButtonDisabled}
        isDisabledTwo={isButtonDisabled}
        isDisabledThree={isButtonSubmitDisabled}
        handleClose={handleCloseModal}
      >
        <div className="hq-modal__content">
          <header className="hq-modal__content-header-sticky">
            <br />
            <Grid item container justifyContent="center" alignItems="center" className="hq-modal__content-grid-header">
              <Grid item xs={8}></Grid>
              <Grid item xs>
                <Typography>Rename file</Typography>
              </Grid>
              <Grid item xs>
                <Typography>Add new version</Typography>
              </Grid>
              <Grid item xs>
                <Typography>Keep existing</Typography>
              </Grid>
            </Grid>
          </header>
          {filesInspection?.duplicated!.map((file) => {
            const extension = getExtension(file.fileName);
            const newName = parseNewName(file);
            const displayName = newName || file.nameWithoutExtension;

            return (
              <Box key={file.documentId}>
                <Grid container direction="column">
                  <Grid item container alignItems="center" key={file.documentId} className="modal_grid-item">
                    <Grid item xs={8} container className="modal_grid-item-file">
                      <div className="modal_grid-item-file-name">
                        <InsertDriveFileOutlined />
                        {editingFile?.id === file.documentId && isBlurEnabled ? (
                          <>
                            <TextField
                              className="modal_grid-item-file-input"
                              value={editingFile?.newFileName}
                              onChange={handleFileNameChange}
                              inputRef={inputRef}
                              autoFocus
                            />
                            <IconButton onClick={() => handleBlur(file)} disabled={isCheckDisabled}>
                              <Check color={iconSelected(!isCheckDisabled)} />
                            </IconButton>
                            <IconButton onClick={() => handelCancelEdit(file)}>
                              <Close />
                            </IconButton>
                          </>
                        ) : (
                          <Typography className="modal_grid-item-file-text">
                            {displayName}.{extension}
                          </Typography>
                        )}
                      </div>
                    </Grid>

                    <Grid item xs container justifyContent="center">
                      <IconButton onClick={() => handleEdit(file)}>
                        <Edit color={iconSelected(!!file.isEdited)} />
                      </IconButton>
                    </Grid>
                    <Grid item xs container justifyContent="center">
                      <IconButton onClick={() => handleOverwrite(file)}>
                        <ContentCopy color={iconSelected(!!file.overWrite)} />
                      </IconButton>
                    </Grid>
                    <Grid item xs container justifyContent="center">
                      <IconButton onClick={() => handleOmit(file)}>
                        <RemoveCircleOutlineOutlined color={iconSelected(!!file.isOmitted)} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          <hr />
        </div>
      </ModalTaxPortalThree>
    </>
  );
}
