import { useEffect, useState } from "react";
import "devextreme/data/odata/store";
import "../style.css";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Sorting,
  Scrolling,
  Grouping,
  Paging,
} from "devextreme-react/data-grid";
import { clientData, siteTemplateId } from "./Addclients";
import { siteTemplates } from "./Addclients";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { useContext } from "react";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { ServiceBusEnum } from "../../../api-client-nswag/taxportal-client";
import CreateSites from "../Createsites";

export const Clients = () => {
  const [sitetemplates, setSiteTemplates] = useState<any[]>([]);
  const { user } = useContext(OidcIdentityContext);
  const selection = useRecoilValue(clientData);
  const templates = useRecoilValue(siteTemplates);
  const templateIds = useRecoilValue(siteTemplateId);
  const removeSelection = useResetRecoilState(clientData);
  const selctionRemove = useSetRecoilState(clientData);
  const removeTemplates = useResetRecoilState(siteTemplates);
  const [show, setShow] = useState(false);
  const [removeClient, setRemoveClient] = useState(1);
  const [selectedSite, setSelectedSite] = useState<any>({ name: "" });

  var jsondata = JSON.parse(`{"sitetemplate":"${templates}"}`);

  useEffect(() => {
    for (var i = 0; i < selection.length; i++) {
      //@ts-expect-error
      const obj = { ...selection[i], ...jsondata };
      setSiteTemplates((e) => {
        return [...e, obj];
      });
    }
  }, []);

  const listData = sitetemplates.map((e) => {
    return JSON.parse(`{
  "siteId":0,
  "userName":"${user?.profile.preferred_username as string}",
  "eventType": ${ServiceBusEnum.SITE_CREATION},
  "status":"INPROGRESS",
  "mdmClientId":"${e.mdmClientId}",
  "templateId":"${templateIds}",
  "templateName":"${templates}",
  "siteName":"${e.name}"}`);
  });

  const remove = () => {
    setSiteTemplates((current) => current.filter((del) => del.mdmClientId !== removeClient));
    selctionRemove(
      (
        prev //@ts-expect-error
      ) => prev.filter((del) => del.mdmClientId !== removeClient)
    );
    setShow(false);
  };

  const cellRender = (e: any) => {
    return (
      <>
        <Button
          onClick={() => {
            setShow(true);
            setRemoveClient(e.key.mdmClientId);
            setSelectedSite({ name: e.key.name });
          }}
          style={{ fontSize: "12px", padding: "2px", textTransform: "none" }}
        >
          Remove
        </Button>
      </>
    );
  };
  const creatingSites = async () => {
    await taxPortalClientApi.taxPortal_CreateSites(listData);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={show}>
        <DialogTitle className="deleteicon">Remove Client </DialogTitle>
        <DialogContent className="deleteicon">Are you sure you want to remove {selectedSite.name}? </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button style={{ marginRight: "20px" }} variant="outlined" onClick={remove}>
            Yes
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <CreateSites />
      <div className="a">
        <Link to="/addclients" onClick={removeSelection}>
          <Button variant="outlined" sx={{ marginBottom: "10px", marginRight: "5px" }} onClick={removeTemplates}>
            Add Client
          </Button>
        </Link>
        <Link to="/sites">
          <Button variant="outlined" sx={{ marginBottom: "10px", marginRight: "20px" }} onClick={creatingSites}>
            Create Sites
          </Button>
        </Link>
      </div>

      <div style={{ marginLeft: "20px", marginRight: "20px", marginTop: "15px" }}>
        <DataGrid dataSource={sitetemplates} showBorders={true} height={600} remoteOperations={true}>
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Sorting mode="multiple" />

          <Paging enabled={true} defaultPageSize={10} />
          <Scrolling columnRenderingMode="virtual" />
          <Grouping autoExpandAll={true} />

          <Column dataField="mdmMasterClientId" caption="Master ClientID" alignment="left"></Column>
          <Column dataField="mdmClientId" caption="ClientID" alignment="left"></Column>
          <Column dataField="name" caption="Client/Entity Name" alignment="left"></Column>
          <Column dataField="sitetemplate" caption="Site Template" alignment="left"></Column>
          <Column width={100} alignment={"left"} cellRender={cellRender} caption={"Action"} />
        </DataGrid>
      </div>
    </>
  );
};
