import React, { useRef } from "react";
import { useState, useEffect, useContext } from "react";
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  InputLabel,
  Pagination,
} from "@mui/material";
import { OidcIdentityContext } from "@rsmus/react-auth/dist/OidcIdentity";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Paging,
  Selection,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { calculateGridHeight, convertUTCTimeToLocal } from "../../util/Utils";
import { dataGridConfig, defaultPageSize } from "../../constants";
import "./SiteAdminAccess.scss";
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { SiteUpdate } from "../UpdateScreen/UpdateSiteInfoAtoms";
import { snackbarState } from "../../cui/commonComponents/CustomSnackbar";
import {
  GetAllRolesDTO,
  ServiceMessage,
  GetAllSitesInfoDTO,
  SelectCustomPermission,
  SelectName,
} from "../../api-client-nswag/taxportal-client";
import {
  GetAllSitesDTO,
  ServiceBusEnum,
  SyncTypeEnum,
} from "../../api-client-nswag/taxportal-client";
import { RoleId } from "./SiteAdmin.types";
import SplitButton from "./SplitButton";
import SplitButtonApplication from "./SplitButtonApplication";
import { isEmpty, isNull } from "lodash";
import AppNameToggle, {
  getAppName,
} from "../../cui/commonComponents/AppNameToggle";
import NestedDropDown from "../../cui/commonComponents/NestedDropDown";
import useSnackbar from "../../hooks/useSnackbar";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";

type DatagridRefType = DataGrid;

export const type = atom({
  key: "SyncType",
  default: SyncTypeEnum.SITE_ADMIN_ACCESS,
});

const SiteAdminAccess = () => {
  const [data, setData] = useRecoilState(SiteUpdate);
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null);
  const [rolesData, setRolesData] = useState<GetAllRolesDTO[]>([]);
  const { user } = useContext(OidcIdentityContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const setSnackbar = useSetRecoilState(snackbarState);
  const dataGridRef = useRef<DatagridRefType>(null);
  const appName = useRecoilValue(getAppName);
  const [pageNumber, setpageNumber] = useState(1);
  const [syncType, setSyncType] = useRecoilState(type);
  const [dropDown, setDropDown] = useState("");
  const { showSnackbar } = useSnackbar();
  const {
    CUSTOM_PERMISSIONS,
    CUSTOM_GROUPS,
    INFORMATION_REQUEST,
    PROJECT_STATUS,
  } = SelectCustomPermission;
  const [setsyncTypeIs, setSetsyncTypeIs] = useState(SyncTypeEnum.PROVIDE_USERGROUP_ACCESS)

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesData: GetAllRolesDTO[] =
          await taxPortalClientApi.taxPortal_GetAllRoles();
        if (!isNull(rolesData) && !isEmpty(rolesData)) {
          const filteredDataCS: GetAllRolesDTO[] = rolesData.filter((item) =>
            item?.name?.startsWith("CS")
          );
          const filteredDataTP: GetAllRolesDTO[] = rolesData.filter(
            (item) => !item?.name?.startsWith("CS")
          );
          if (appName === "CS") {
            setRolesData(filteredDataCS);
          } else if (appName === "TP") {
            setRolesData(filteredDataTP);
          }
        }
      } catch (error) {}
    };
    fetchRoles();
  }, [appName]);

  useEffect(() => {
    setDropDown(CUSTOM_PERMISSIONS);
  }, []);

  const fetchSites = async () => {
    try {
      dataGridRef.current!.instance.clearSelection();
      setData({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
      const startIndex =
        pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const data = await taxPortalClientApi.taxPortal_GetAllSites(
        appName === "IS" ? setsyncTypeIs : syncType,
        null,
        appName !== null ? appName : "PERF",
        startIndex,
        defaultPageSize
      );

      setData({
        pending: false,
        fulfilled: true,
        sites: data.data,
        totalCount: data.totalCount,
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (searchTerm === null || searchTerm?.length === 0) {
      fetchSites();
    }
  }, [pageNumber, appName, searchTerm]);
  const handleClick = async () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const message = selectedData.map((e: any) => {
      return {
        siteId: e.hqSiteId,
        userName: user?.profile.preferred_username,
        eventType: ServiceBusEnum.SITE_ADMIN_ACCESS,
      };
    });
    try {
      setSnackbar({
        open: true,
        message: "Provide Content Admin access in Progress.",
        severity: "info",
      });
      await taxPortalClientApi.taxPortal_InsertTopic(message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: SelectChangeEvent) => {
    setDropDown(e.target.value);
  };

  const handleIURClick = async () => {
    let type = 0;
    if (appName === "TP") {
      type = ServiceBusEnum.INTERNAL_USER_ASSIGNMENT;
    }
    if (appName === "CS") {
      type = ServiceBusEnum.INTERNAL_USER_ASSIGNMENT_CS;
    }
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const message = selectedData.map((e: any) => {
      return {
        siteId: e.hqSiteId,
        userName: user?.profile.preferred_username,
        eventType: type,
      };
    });
    try {
      setSnackbar({
        open: true,
        message: "Internal User Assignment in Progress.",
        severity: "info",
      });
      await taxPortalClientApi.taxPortal_InsertTopic(message);
      if (appName === "TP") {
        type = SyncTypeEnum.INTERNAL_USER_ASSIGNMENT;
      }
      if (appName === "CS") {
        type = SyncTypeEnum.INTERNAL_USER_ASSIGNMENT_CS;
      }
      setSyncType(type);
    } catch (error) {
      console.log(error);
    }
  };
  const handleTESSClick = async () => {
    let type = 0;
    if (appName === "IS") {
      const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
      const message = selectedData.map((e: any) => {
        return {
          siteId: e.hqSiteId,
          userName: user?.profile.preferred_username,
          eventType: ServiceBusEnum.TESS_ASSIGNMENT,
        };
      });
      try {
        setSnackbar({
          open: true,
          message: "TESS Assignment in Progress.",
          severity: "info",
        });
        await taxPortalClientApi.taxPortal_InsertTopic(message);
        type = ServiceBusEnum.TESS_ASSIGNMENT;
        setSyncType(type);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleRoleClick = async (roleId: string, roleName: string) => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const message = selectedData.map((e: any) => ({
      siteId: e.hqSiteId,
      userName: user?.profile.preferred_username as string,
      eventType: ServiceBusEnum.PROVIDE_APP_ACCESS,
      roleId: roleId,
      roleName: roleName,
    }));
    try {
      setSnackbar({
        open: true,
        message: "Provide Site Role access in Progress.",
        severity: "info",
      });
      await taxPortalClientApi.taxPortal_ProvideAppAccess(message);
      setSyncType(SyncTypeEnum.CLIENT_LEVEL_ROLE_ASSIGN);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTESSForIsClick = async () => {
    setSnackbar({
      open: true,
      message: "Provide User Group Access In Progress",
      severity: "info",
    });
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    try {
      await Promise.all(
        selectedData.map(async (e) => {
          await taxPortalClientApi.internalSite_AddUserGroupUsersToIs(
            e.hqSiteId,
            user?.profile.preferred_username as string
          );
        })
      );
      setSetsyncTypeIs(SyncTypeEnum.PROVIDE_USERGROUP_ACCESS);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTESSIsheet = async () => {
    setSnackbar({
      open: true,
      message: "Provide User TESS Access In Progress",
      severity: "info",
    });
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();

    const data = selectedData.map(
      (e: GetAllSitesDTO): ServiceMessage => ({
        siteId: e.hqSiteId,
        userName: user?.profile.preferred_username,
        eventType: ServiceBusEnum.TESS_ISHEET_ASSIGNMENT,
      })
    );
    await taxPortalClientApi.taxPortal_InsertTopic(data);
    setSetsyncTypeIs(SyncTypeEnum.TESS_ISHEET_ASSIGNMENT);
  };

  const handleClickApp = async (option: string) => {
    const message = JSON.parse(
      `{"userName" :"${
        user?.profile.preferred_username as string
      }", "ApplicationGroupType" : "${option}"}`
    );
    try {
      setSnackbar({
        open: true,
        message: "Provide Applicaion access in Progress.",
        severity: "info",
      });
      await taxPortalClientApi.taxPortal_ProvideTESSAccess(message);
    } catch (error) {
      console.log(error);
    }
  };

  const names: SelectCustomPermission[] = [
    CUSTOM_PERMISSIONS,
    CUSTOM_GROUPS,
    INFORMATION_REQUEST,
    PROJECT_STATUS,
  ];

  useEffect(() => {
    const handleUpdateClick = async (): Promise<void> => {
      try {
        const dropDownSelect: Record<string, ServiceBusEnum> = {
          [CUSTOM_GROUPS]: ServiceBusEnum.UPDATE_CUSTOM_GROUPS,
          [INFORMATION_REQUEST]: ServiceBusEnum.UPDATE_IR_VIEWS,
          [PROJECT_STATUS]: ServiceBusEnum.UPDATE_PS_VIEWS,
        };

        const selectedValue = dropDownSelect[dropDown];

        if (selectedValue) {
          showSnackbar({ message: SnackbarMessage.CUSTOM_PERMISSIONS });
          const selectedData =
            dataGridRef.current!.instance.getSelectedRowsData();
          const data = selectedData.map(
            (e: GetAllSitesInfoDTO): ServiceMessage => ({
              siteId: e.hqSiteId,
              userName: user?.profile.preferred_username,
              eventType: selectedValue,
              mdmClientId: e.mdmClientId,
            })
          );

          await taxPortalClientApi.taxPortal_InsertTopic(data);
          dataGridRef.current!.instance.clearSelection();
          setDropDown(CUSTOM_PERMISSIONS);
        }
      } catch (error) {
        showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      }
    };
    handleUpdateClick();
  }, [dropDown]);

  const handleExternalUser = async (option: string) => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();

    const getEventType = (
      appName: string | null | undefined,
      option: string
    ) => {
      if (isNull(appName) || isEmpty(appName) || appName === "TP") {
        switch (option) {
          case "IDM and CEM":
            return ServiceBusEnum.CREATE_EXTERNAL_USER;
          case "Assign User to site":
            return ServiceBusEnum.CREATE_EXTERNAL_USER;
          case "Remove User":
            return ServiceBusEnum.REMOVE_EXTERNAL_USER;
          case "Check Status":
            return ServiceBusEnum.CHECK_EXTERNAL_USER_STATUS;
        }
      } else {
        switch (option) {
          case "IDM and CEM":
            return ServiceBusEnum.CREATE_EXTERNAL_USER_CS;
          case "Assign User to site":
            return ServiceBusEnum.CREATE_EXTERNAL_USER_CS;
          case "Remove User":
            return ServiceBusEnum.REMOVE_EXTERNAL_USER;
          case "Check Status":
            return ServiceBusEnum.CHECK_EXTERNAL_USER_STATUS;
        }
      }
    };

    const callServiceBus = async (e: any, eventType: any) => {
      await taxPortalClientApi.taxPortal_InsertTopic([
        {
          siteId: e.hqSiteId,
          userName: user?.profile.preferred_username as string,
          eventType: eventType,
          externalUserType: option,
          clientName: e.hqClientName,
          mdmClientId: e.mdmClientId,
          isheetType: "Client User Requests",
        },
      ]);
    };

    switch (option) {
      case "IDM and CEM":
      case "Assign User to site":
      case "Check Status":
      case "Remove User":
        selectedData.forEach((e: any) => {
          const eventType = getEventType(e.appName, option);
          callServiceBus(e, eventType);
        });
        break;
      default:
        // Handle unexpected option
        break;
    }

    let snackbarMessage = "";
    switch (option) {
      case "IDM and CEM":
        snackbarMessage = "Adding External User in Progress.";
        break;
      case "Assign User to site":
        snackbarMessage = "Assign User to site in Progress.";
        break;
      case "Check Status":
        snackbarMessage = "Check Status in Progress.";
        break;
      case "Remove User":
        snackbarMessage = "Removing External User in Progress.";
        break;
      default:
        snackbarMessage = "Operation in Progress.";
        break;
    }

    setSnackbar({
      open: true,
      message: snackbarMessage,
      severity: "info",
    });

    if (isNull(appName) || isEmpty(appName) || appName === "TP") {
      setSyncType(SyncTypeEnum.CREATE_EXTERNAL_USER);
    } else {
      setSyncType(SyncTypeEnum.CREATE_EXTERNAL_USER_CS);
    }

    try {
      // Add your try-catch logic here if needed
    } catch (error) {
      console.log(error);
    }
  };

  const handleThirdPartyUser = async (option: string) => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();

    const getEventType = (
      appName: string | null | undefined,
      option: string
    ) => {
      if (isNull(appName) || isEmpty(appName) || appName === "TP") {
        switch (option) {
          case "IDM and CEM":
            return ServiceBusEnum.CREATE_EXTERNAL_USER_THIRD_PARTY;
          case "Assign User to site":
            return ServiceBusEnum.CREATE_EXTERNAL_USER_THIRD_PARTY;
          case "Remove User":
            return ServiceBusEnum.REMOVE_EXTERNAL_USER;
          case "Check Status":
            return ServiceBusEnum.CHECK_EXTERNAL_USER_STATUS;
        }
      }
    };

    const callServiceBus = async (e: any, eventType: any) => {
      await taxPortalClientApi.taxPortal_InsertTopic([
        {
          siteId: e.hqSiteId,
          userName: user?.profile.preferred_username as string,
          eventType: eventType,
          externalUserType: option,
          clientName: e.hqClientName,
          mdmClientId: e.mdmClientId,
          isheetType: "Third-party User Request Form",
        },
      ]);
    };

    switch (option) {
      case "IDM and CEM":
      case "Assign User to site":
      case "Check Status":
      case "Remove User":
        selectedData.forEach((e: any) => {
          const eventType = getEventType(e.appName, option);
          callServiceBus(e, eventType);
        });
        break;
      default:
        // Handle unexpected option
        break;
    }

    let snackbarMessage = "";
    switch (option) {
      case "IDM and CEM":
        snackbarMessage = "Adding Third-Party User in Progress.";
        break;
      case "Assign User to site":
        snackbarMessage = "Assign User to site in Progress.";
        break;
      case "Check Status":
        snackbarMessage = "Check Status in Progress.";
        break;
      case "Remove User":
        snackbarMessage = "Removing Third-Party User in Progress.";
        break;
      default:
        snackbarMessage = "Operation in Progress.";
        break;
    }

    setSnackbar({
      open: true,
      message: snackbarMessage,
      severity: "info",
    });

    setSyncType(SyncTypeEnum.CREATE_EXTERNAL_USER_THIRD_PARTY);
  };

  const cellRenderSiteCreated = (e: any) => {
    return <div>{convertUTCTimeToLocal(e.data.siteCreated)}</div>;
  };
  const cellRenderLastUpdate = (e: any) => {
    return <div>{convertUTCTimeToLocal(e.data.lastModified)}</div>;
  };
  const onSelectionchanged = (e: any) => {
    setSelectedRows(e.selectedRowsData);
  };

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: async () => {
      setSearchTerm(null);
      if (dataGridRef.current) {
        dataGridRef.current.instance.clearFilter();
      }
      fetchSites();
    },
  };

  const fetchSitesBySearchTerms = async () => {
    try {
      setData({ pending: true, fulfilled: false, sites: [], totalCount: 0 });
      const res = await taxPortalClientApi.taxPortal_GetAllSiteSearch(
        SyncTypeEnum.SITE_ADMIN_ACCESS,
        appName !== null ? appName : "PERF",
        searchTerm
      );
      if (res != null) {
        setData({
          pending: false,
          fulfilled: true,
          sites: res.data,
          totalCount: res.totalCount,
        });
      } else {
        setData({ pending: false, fulfilled: true, sites: [], totalCount: 0 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
      fetchSitesBySearchTerms();
    }
    // eslint-disable-next-line
  }, [appName, searchTerm]);

  return (
    <div className="site-admin-container">
      <div
        style={{
          display: "flex",
          margin: "10px 0 10px 0",
        }}
      >
        <h2>Assign Users</h2>
        <AppNameToggle />
      </div>
      <DataGrid
        height={calculateGridHeight(254)}
        onSelectionChanged={onSelectionchanged}
        {...dataGridConfig}
        dataSource={data.sites}
        noDataText={data.pending ? "Loading..." : "No data"}
        ref={dataGridRef}
      >
        <Selection
          mode="multiple"
          selectAllMode={"allPages"}
          showCheckBoxesMode={"onClick"}
        />
        <Scrolling mode="standard" />
        <LoadPanel enabled={false} />
        <SearchPanel
          placeholder={"Global Search..."}
          onTextChange={(e) => {
            setSearchTerm(e);
          }}
          visible
        />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling columnRenderingMode="virtual" />
        <Toolbar>
          <Item location="after">
            <FormControl size="small" variant="outlined">
              <Select
                value={dropDown}
                style={{ height: 36, fontSize: "14px" }}
                defaultValue="Custom Permissions"
                onChange={handleChange}
              >
                {names.map((name) => (
                  <MenuItem
                    disabled={
                      appName === "CS" ||
                      selectedRows.length < 1 ||
                      name == "Custom Permissions"
                    }
                    key={name}
                    value={name}
                    style={{ fontSize: "14px" }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          <Item location="after">
            <NestedDropDown
              rolesData={rolesData}
              provideAccess={selectedRows.length > 0 ? false : true}
              handleRoleClick={handleRoleClick}
              handleClick={handleClick}
              handleClickApp={handleClickApp}
              handleExternalUser={handleExternalUser}
              handleThirdPartyUser={handleThirdPartyUser}
              appName={appName}
              handleTESSForIsClick={handleTESSForIsClick}
              handleTESSIsheet={handleTESSIsheet}
              handleIURClick={handleIURClick}
              handleTESSClick={handleTESSClick}
            />
          </Item>
          <Item name="searchPanel" location="after" />
          <Item
            widget="dxButton"
            options={refreshButtonOptions}
            name="alignRight"
          />
        </Toolbar>
        <Column
          width={20}
          alignment="left"
          dataField="hqSiteId"
          caption={"SiteID"}
        ></Column>
        <Column
          width={130}
          alignment="left"
          dataField="mdmClientId"
          caption={"MDMClientID"}
        ></Column>
        <Column
          minWidth={140}
          dataField="hqClientName"
          caption={"SiteName"}
        ></Column>
        <Column
          minWidth={150}
          dataField="siteCreatedBy"
          caption={"Site Created by"}
        ></Column>
        <Column
          minWidth={170}
          cellRender={cellRenderSiteCreated}
          dataField="Site Created Date / Time"
          caption={"Site Created Date / Time"}
        ></Column>

        <Column
          minWidth={140}
          dataField="lastModifiedBy"
          caption={"Last Updated by"}
        ></Column>
        <Column
          minWidth={160}
          cellRender={cellRenderLastUpdate}
          dataField="Last Update Date/Time"
          caption={"Last Update Date/Time"}
        ></Column>
      </DataGrid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "5px 0 0 0",
        }}
      >
        <Pagination
          onChange={(e: any, pageNumber: any) => {
            setpageNumber(pageNumber);
          }}
          count={Math.ceil((data.totalCount as number) / defaultPageSize)}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default SiteAdminAccess;
