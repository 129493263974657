import { getAccessToken } from '@rsmus/react-auth'
import { v4 as uuidv4 } from 'uuid';

/**
 * This file being used in the generation of API clients and 
 * this file should not be removed
 */

export class AuthorizedApiBase {
  uniqueGuid: string;
  userId: string;

  constructor() {
    this.uniqueGuid = "0";
    this.userId = "0"
  }

  protected transformOptions = async (
    options: RequestInit,
  ): Promise<RequestInit> => {
    options.headers = {
      ...options.headers,
      Authorization: await getAccessToken().then((at) => 'Bearer ' + at),
      'X-Correlation-ID': `${this.getUserId()}_${this.getUniqueId()}`,
    }
    return Promise.resolve(options)
  }

  private getUniqueId(): string {
    this.uniqueGuid = uuidv4();
    return this.uniqueGuid;
  }

  private getUserId(): string {
    Object.keys(sessionStorage).forEach((key, value) => {
      if(key.startsWith('oidc.user:')) {
        const userData = sessionStorage.getItem(key)?.toString() ?? '';
        this.userId = JSON.parse(userData).profile.rsmuid;        
      }
    });
    return this.userId ?? '';
  }
}
