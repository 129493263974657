import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ModalProps } from "./Modal.types";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const defaultStyles = {
  position: "center",
  margin: "32px",
  height: "calc(100% - 64px);",
  maxHeight: "max-height: calc(100% - 64px);",
  overflowY: "hiden",
};

export const Modal = ({
  title = "",
  btnOpenText = "",
  btnCloseText = "",
  thirdBtnText = "",
  btnCloseTop = false,
  maxWidth = "md",
  isDisplayModal,
  handleContinue,
  handleClose,
  handleThirdBtn,
  styles = defaultStyles,
  children,
}: ModalProps) => {
  return (
    <>
      <Dialog
        open={isDisplayModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={maxWidth}
        className="cui-c_modal"
        sx={{
          "& .MuiDialog-container": {
            justifyContent: styles.position,
          },
          "& .MuiDialog-paper": {
            margin: styles.margin,
            height: styles.height,
            maxHeight: styles.maxHeight,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" className="cui-c_modal-title">
          {title}
        </DialogTitle>
        {btnCloseTop ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <DialogContent className="cui-c_modal-body">{children}</DialogContent>
        <DialogActions className="cui-c_modal-footer">
          {!!btnOpenText?.length ? (
            <Button onClick={handleContinue} variant="contained">
              {btnOpenText}
            </Button>
          ) : null}
          {!!btnCloseText?.length ? (
            <Button onClick={handleClose} variant="outlined">
              {btnCloseText}
            </Button>
          ) : null}
          {!!thirdBtnText?.length ? (
            <Button onClick={handleThirdBtn} variant="outlined">
              {thirdBtnText}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};
