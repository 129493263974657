import { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import DataGrid, { Column, Editing, Paging, RequiredRule, Selection } from "devextreme-react/data-grid";
import { OidcIdentityContext } from "@rsmus/react-auth";

import { dataGridConfig } from "../../constants";
import { reducer } from "./reducer/reducer";
import { fetchData, applyChanges, updateChanges, setEditRowKey } from "./reducer/actions";

import {
  COL_OPTION,
  EditModeType,
  InitialSiteSettingsStateProps,
  PARENT_TYPE,
  enabledOnAdd,
} from "./SiteSettingsManager.types";
import { IChangeProps } from "devextreme-react/tree-list";

import useSnackbar from "../../hooks/useSnackbar";
import { SitesTable } from "../../cui/components/SitesTable/SitesTable";
import ModalUnsavedWarning from "../../cui/components/Modal/ModalUnsavedWarning";
import { GetSiteSettingsDTO } from "../../api-client-nswag/taxportal-client";
import { SnackbarMessage } from "../../hooks";
import AppNameToggle, { getAppName } from "../../cui/commonComponents/AppNameToggle";
import { useRecoilValue } from "recoil";

const initialSiteSettingsState: InitialSiteSettingsStateProps = {
  data: [],
  changes: [],
  editRowKey: null,
  editRow: null,
  isLoading: false,
  userName: "",
};

type DatagridRefType = DataGrid;

export const SiteSettingsManager = () => {
  const { user } = useContext(OidcIdentityContext);
  const userName = user?.profile.preferred_username!;
  const { showSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState<GetSiteSettingsDTO[]>([]);
  const siteSettingsGridRef = useRef<DatagridRefType>(null);
  const settingsGridRef = siteSettingsGridRef.current?.instance ?? null;

  const [siteSettingsState, dispatch] = useReducer(reducer, initialSiteSettingsState);
  const [saveMode, setSaveMode] = useState<EditModeType>({
    isEditing: false,
    isSaving: false,
  });

  const onEditRowKeyChange = useCallback((editRowKey: number) => {
    setEditRowKey(dispatch, editRowKey);
    setSaveMode({
      isEditing: true,
      isSaving: false,
    });
  }, []);

  const onChangesChange = useCallback((changes: IChangeProps[]) => {
    updateChanges(dispatch, changes);
  }, []);

  const onSaving = useCallback(
    (e: any, userName: string) => {
      e.cancel = true;
      e.promise = applyChanges(dispatch, e.changes[0], siteSettingsState, userName, showSnackbar);
      e.promise.then((response: Promise<string>) => {
        enableDisableCell(e.component, true);
        displaySuccesssMessage(e.changes[0].type);
        fetchData(dispatch, showSnackbar);
      });
      setSaveMode({
        isEditing: false,
        isSaving: true,
      });
    },
    [siteSettingsState]
  );

  const displaySuccesssMessage = (type: string) => {
    let message: SnackbarMessage;
    switch (type) {
      case "update":
        message = SnackbarMessage.SITE_SETTING_UPDATED;
        break;
      case "remove":
        message = SnackbarMessage.SITE_SETTING_DELETED;
        break;
      default:
        message = SnackbarMessage.SITE_SETTING_CREATED;
    }
    showSnackbar({ message: message, severity: "success" });
  };

  const onEditCanceled = (e: any) => {
    setSaveMode({
      isEditing: false,
      isSaving: true,
    });
    enableDisableCell(e.component, true);
  };

  const onEditorPreparing = useCallback((e: any) => {
    if (enabledOnAdd.includes(e.dataField) && e.parentType === PARENT_TYPE) {
      enableDisableCell(e.component, e.row.isNewRow);
    }
  }, []);

  const enableDisableCell = (component: any, value: boolean) => {
    enabledOnAdd.forEach((element) => {
      component.columnOption(element, COL_OPTION, value);
    });
  };

  const getSelectedData = () => {
    const selectedData = siteSettingsGridRef.current!.instance.getSelectedRowsData();
    setSelectedRows(selectedData);
    return selectedData;
  };

  const onRowValidating = (e: any) => {
    if (e.oldData !== undefined) return;
    if (!e.newData.setting) {
      e.isValid = false;
      e.errorText = SnackbarMessage.SITE_SETTINGS_FIELD_REQUIRED;
    } else if (textExist(siteSettingsState.data, e.newData.setting)) {
      const message = SnackbarMessage.SITE_SETTING_EXIST.replace("%item%", e.newData.setting) as SnackbarMessage;
      e.isValid = false;
      e.errorText = message;
    }
  };

  const textExist = (data: GetSiteSettingsDTO[], newText: string) => {
    return data.some((setting) => normalizeText(setting.setting) === normalizeText(newText));
  };

  const normalizeText = (text: string) => {
    return text.toLowerCase().trim();
  };

  const onInitNewRow = (e: any) => {
    e.component.cancelEditData();
  };

  useEffect(() => {
    fetchData(dispatch, showSnackbar);
  }, []);

  return (
    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
      <div style={{ marginLeft: "3px", marginTop: "40px", marginBottom:"5px", display:'flex' }}>
        <h2>Create / Update Site Settings</h2>
        <AppNameToggle />
      </div>
      <DataGrid
        id="gridContainer"
        keyExpr="id"
        dataSource={siteSettingsState.data}
        {...dataGridConfig}
        columnMinWidth={50}
        width={"65%"}
        noDataText={siteSettingsState.isLoading ? "Loading..." : "No data"}
        repaintChangesOnly
        onSaving={(e) => onSaving(e, userName)}
        onEditCanceled={onEditCanceled}
        onEditorPreparing={onEditorPreparing}
        onInitNewRow={onInitNewRow}
        onSelectionChanged={getSelectedData}
        onRowValidating={onRowValidating}
        ref={siteSettingsGridRef}
      >
        <Paging enabled={true} defaultPageSize={10} />
        <Selection mode="multiple" selectAllMode={"allPages"} />
        <Editing
          mode="row"
          allowUpdating
          allowDeleting
          allowAdding
          useIcons
          changes={siteSettingsState.changes}
          onChangesChange={onChangesChange}
          editRowKey={siteSettingsState.editRowKey}
          onEditRowKeyChange={onEditRowKeyChange}
        />
        <Column caption="Setting*" dataField="setting">
          <RequiredRule />
        </Column>
        <Column caption="Description" dataField="description" />
        <Column caption="Value" dataField="value" width={50} />
        <Column caption="Action - Display Name" dataField="actionDisplayName" width={160} />
        <Column caption="Action - URL" dataField="actionUrl" width={150} />
      </DataGrid>
      <SitesTable
        getSelectedSettings={getSelectedData}
        selectedSettings={selectedRows}
        settingsInstance={settingsGridRef}
      />
      <ModalUnsavedWarning when={saveMode.isEditing && !saveMode.isSaving} />
    </div>
  );
};
