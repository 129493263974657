import { useContext, useEffect, useState } from "react";
import { dataGridConfig } from "../../../constants";
import DataGrid, {
  Column,
  Scrolling,
  LoadPanel,
} from "devextreme-react/data-grid";
import { CheckBox } from "devextreme-react/check-box";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { isEmpty, isNull } from "lodash";

const LegalEntitesPermission = (props: any) => {
  const { user } = useContext(OidcIdentityContext);
  const [arrData, setArrData] = useState<any[]>([]);
  useEffect(() => {
    if (props.selectedLegalEntityData) {
      const mappedData = props.selectedLegalEntityData.map((item: any) => ({
        mdmClientId: props.selectedRowsData?.mdmClientId,
        clientId: item.clientId,
        clientName: item.clientName,
        hqClientName: "",
        hqSiteId: 0,
        suppressFolderCreation: false,
        suppressDropdown: false,
        suppressProjectStatus: false,
        userName: user?.profile.preferred_username as string,
      }));
      props.setDataPermission(mappedData);
    }
  }, [props.selectedLegalEntityData]);
  const data = localStorage.getItem("data") as string;
  const storedArray = JSON.parse(data);

  useEffect(() => {
    if (!isNull(storedArray) && !isEmpty(storedArray)) {
      const mappedData = props.selectedLegalEntityData.map((item: any) => ({
        mdmClientId: props.selectedRowsData?.mdmClientId,
        clientId: item.clientId,
        clientName: item.clientName,
        hqClientName: "",
        hqSiteId: 0,
        suppressFolderCreation: false,
        suppressDropdown: false,
        suppressProjectStatus: false,
        userName: user?.profile.preferred_username as string,
      }));

      const combinedArray = storedArray.concat(mappedData);

      const uniqueArray = Array.from(
        new Set(combinedArray.map((obj: any) => obj.clientId))
      ).map((clientId) =>
        combinedArray.find((obj: any) => obj.clientId === clientId)
      );
      const updatedArr = uniqueArray.filter((item: any) =>
        props.selectedLegalEntityData.some(
          (selectedItem: any) => selectedItem.clientId === item.clientId
        )
      );
      localStorage.setItem("data", JSON.stringify(updatedArr));
      setArrData(updatedArr);
      props.setDataPermission(updatedArr);
    }
  }, []);

  const cellRender = (e: any) => {
    return (
      <CheckBox
        defaultValue={
          arrData.length > 0 && e.data.suppressFolderCreation !== undefined
            ? e.data.suppressFolderCreation
            : false
        }
        onValueChanged={(a) => {
          const modifiedData = props.dataPermission.map((b: any) => {
            if (b.clientId === e.data.clientId) {
              b.suppressFolderCreation = a.value;
            }
            return b;
          });
          props.setDataPermission(modifiedData);
          localStorage.setItem("data", JSON.stringify(modifiedData));
        }}
      />
    );
  };
  const cellRender2 = (e: any) => {
    return (
      <CheckBox
        defaultValue={
          arrData.length > 0 && e.data.suppressDropdown !== undefined
            ? e.data.suppressDropdown
            : false
        }
        onValueChanged={(a) => {
          const modifiedData = props.dataPermission.map((b: any) => {
            if (b.clientId === e.data.clientId) {
              b.suppressDropdown = a.value;
            }
            return b;
          });
          props.setDataPermission(modifiedData);
          localStorage.setItem("data", JSON.stringify(modifiedData));
        }}
      />
    );
  };
  const cellRender3 = (e: any) => {
    return (
      <CheckBox
        defaultValue={
          arrData.length > 0 && e.data.suppressProjectStatus !== undefined
            ? e.data.suppressProjectStatus
            : false
        }
        onValueChanged={(a) => {
          const modifiedData = props.dataPermission.map((b: any) => {
            if (b.clientId === e.data.clientId) {
              b.suppressProjectStatus = a.value;
            }
            return b;
          });
          props.setDataPermission(modifiedData);
          localStorage.setItem("data", JSON.stringify(modifiedData));
        }}
      />
    );
  };
  return (
    <div>
      <div style={{ margin: "0 0 16px 0" }}>{props.selectedRowsData?.name}</div>
      <DataGrid
        {...dataGridConfig}
        showBorders={true}
        height={250}
        dataSource={
          arrData.length > 0 ? arrData : props.selectedLegalEntityData
        }
      >
        <Scrolling columnRenderingMode="virtual" />
        <LoadPanel enabled={false} />
        <Scrolling columnRenderingMode="virtual" />
        <Column
          width={130}
          alignment="left"
          dataField="clientId"
          caption={"Client Id"}
        ></Column>
        <Column
          alignment="left"
          minWidth={180}
          dataField="clientName"
          caption={"Client Name"}
        ></Column>
        <Column
          alignment="center"
          width={230}
          type={"checkbox"}
          cellRender={cellRender}
          caption={"Suppress in Folder creation in Files"}
        ></Column>
        <Column
          alignment="center"
          width={220}
          cellRender={cellRender2}
          caption={"Suppress in Information Request"}
        ></Column>
        <Column
          alignment="center"
          width={180}
          cellRender={cellRender3}
          caption={"Suppress in Project Status"}
        ></Column>
      </DataGrid>
    </div>
  );
};

export default LegalEntitesPermission;
