//@ts-nocheck
import { useContext, useEffect, useRef, useState } from "react";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Selection,
  SearchPanel,
  Toolbar,
  Item,
  Paging,
  Button as ButtonDevEx,
} from "devextreme-react/data-grid";
import { RecoilState, useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { clientData } from "./pages/Addclients";
import { calculateGridHeight, convertUTCTimeToLocal } from "../../util/Utils";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import useSnackbar from "../../hooks/useSnackbar";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { OidcIdentityContext } from "@rsmus/react-auth";
import SplitButton from "../../cui/commonComponents/SplitButton";
import SelectEditLegalEntities from "./selectClientAndTemplate/SelectEditLegalEntities";
import LegalEntitesEditPermission from "./selectClientAndTemplate/LegalEntitesEditPermission";
import { MDMLegalEntityDTO } from "../../api-client-nswag/taxportal-client";
import SelectClientAndTemplate, {
  PermissionDataType,
} from "./selectClientAndTemplate/SelectClientAndTemplate";
import { DialogBox } from "./DialogBox";
import {
  ArchiveSitesDTO,
  ServiceBusEnum,
} from "../../api-client-nswag/taxportal-client";
import { SiteHistory } from "./siteHistory/SiteHistory";
import { isEmpty, isNull } from "lodash";
import { atom } from "recoil";
import AppNameToggle, {
  getAppName,
} from "../../cui/commonComponents/AppNameToggle";
import ModifySiteName from "./selectClientAndTemplate/ModifySiteName";
import { SiteConfig } from "../../api-client-nswag/taxportal-client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Link,
  Pagination,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { envProperties } from "../../util/EnvironmentProperties";
import CustomizeSiteNameForIS from "./CustomizeSiteNameForIS";
import SelectClientAndTemplateForIS from "./selectClientAndTemplate/SelectClientAndTemplateForIS";
import { defaultPageSize } from "../../constants";
import { format } from 'date-fns';

interface selectedOptionType {
  value: number;
  label: string | undefined;
}
interface gridLegalEntityType {
  pending: boolean;
  fulfilled: boolean;
  gridLegalData: MDMLegalEntityDTO[];
}
export const getSiteCreationResponse = atom({
  key: "getSiteCreationResponse",
  default: false,
});

export const getSiteExistResponse = atom({
  key: "getSiteExistResponse",
  default: "",
});

export const getTaxYearResponse = atom({
  key: "getTaxYearResponse",
  default: null,
});

export const getTaxYearResponseForCS = atom({
  key: "getTaxYearResponseForCS2",
  default: null,
});

export interface getSites {
  pending: boolean;
  data: GetAllSitesInfoDTO[] | undefined;
  totalCount: number;
}

export default function SitesWithLegalEntities() {
  const dataGridRef = useRef(null);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [ds, setDs] = useState([]);
  const [data, setData] = useState<getSites>({
    pending: true,
    data: [],
    totalCount: 0,
  });
  const selection = useRecoilValue(clientData);
  const [selectedData, setSelectedData] = useState([]);
  const [tab, setTab] = useState(1);
  const { showSnackbar } = useSnackbar();
  const { showSuccessSnackbar } = useSnackbar();
  const { user } = useContext(OidcIdentityContext);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [selectedOption, setSelectedOption] =
    useState<selectedOptionType | null>(null);
  const [title, setTitle] = useState("");
  const appName = useRecoilValue(getAppName);
  const [btnContinueDisable, setBtnContinueDisable] = useState(false);
  const [activeDiv, setActiveDiv] = useState(null);
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const [isDisplaySiteSettings, setisDisplaySiteSettings] = useState(false);
  const [isDisplayLegalEntities, setisDisplayLegalEntities] = useState(false);
  const [isDisplaySiteName, setIsDisplaySiteName] = useState(false);
  const [isDisplayDates, setisDisplayDates] = useState(false);
  const [isDisplayTaxYear, setisDisplayTaxYear] = useState(false);
  const [taxYear, setTaxYear] = useRecoilState<any>(getTaxYearResponse);
  const [taxYearForCS, setTaxYearForCS] = useRecoilState<any>(getTaxYearResponseForCS);
  const [checked, setChecked] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null);
  const [handleEdit, sethandleEdit] = useState(false);
  const removeTaxYear = useResetRecoilState(getTaxYearResponse);
  const removeTaxYearForCS = useResetRecoilState(getTaxYearResponseForCS);
  const [selectedLegalEntityData, setSelectedLegalEntityData] = useState<any[]>(
    []
  );
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
  const [legalData, setLegalData] = useState<gridLegalEntityType>({
    pending: false,
    fulfilled: false,
    gridLegalData: [],
  });
  const [isFlag, setIsFlag] = useRecoilState(getSiteCreationResponse);
  const [siteConfig, setSiteConfig] = useState<SiteConfig>({});
  const [siteExistResponse, setIsSiteExistResponse] =
    useRecoilState(getSiteExistResponse);
  const [handleRemoveSiteSettings, setHandleRemoveSiteSettings] =
    useState(false);
  const [popup, setPopup] = useState(false);
  const [dataPermissionFinal, setDataPermissionFinal] = useState<
    PermissionDataType[]
  >([
    {
      mdmClientId: 0,
      clientId: 0,
      clientName: "",
      hqSiteId: 0,
      hqClientName: "",
      suppressFolderCreation: false,
      suppressDropdown: false,
      suppressProjectStatus: false,
      userName: "",
    },
  ]);
  const [editDataPermissionFinal, seteditDataPermissionFinal] = useState<
    PermissionDataType[]
  >([
    {
      mdmClientId: 0,
      clientId: 0,
      clientName: "",
      hqSiteId: 0,
      hqClientName: "",
      suppressFolderCreation: false,
      suppressDropdown: false,
      suppressProjectStatus: false,
      userName: "",
    },
  ]);
  const [selectionMode, setSelectionMode] = useState("multiple");
  const [selectedRowsData, setSelectedRowsData] =
    useState<selectedRowDataTypes | null>(null);

  const [siteNameForIS, setsiteNameForIS] = useState("");
  // Function to handle div click
  const handleDivClick = (divNumber) => {
    setpageNumber(1);
    setActiveDiv(divNumber);
  };

  const fetchData = async () => {
    try {
      setData({ pending: true, data: [], totalCount: 0 });
      const startIndex =
        pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const data = await taxPortalClientApi.highQ_GetSiteFromTrack(
        activeDiv,
        appName !== null ? appName : "PERF",
        startIndex,
        defaultPageSize
      );
      setData({ pending: false, data: data.data, totalCount: data.totalCount });
    } catch (error) {}
  };

  const fetchDataBySearch = async () => {
    try {
      setData({ pending: true, data: [], totalCount: 0 });
      const startIndex =
        pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const data = await taxPortalClientApi.highQ_GetSiteFromTrackSearch(
        activeDiv,
        searchTerm,
        appName !== null ? appName : "PERF"
      );
      setData({ pending: false, data: data.data, totalCount: data.totalCount });
    } catch (error) {}
  };

  useEffect(() => {
    if ((searchTerm === null || searchTerm?.length === 0) && shouldFetch) {
      fetchData();
      setShouldFetch(false);
    }
  }, [activeDiv, searchTerm, pageNumber, appName, shouldFetch]);

  useEffect(() => {
    setpageNumber(1);
    setShouldFetch(true);
  }, [appName, activeDiv]);

  useEffect(() => {
    setShouldFetch(true);
  }, [pageNumber, searchTerm]);

  useEffect(() => {
    if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
      fetchDataBySearch();
    }
    // eslint-disable-next-line
  }, [activeDiv, appName, pageNumber, searchTerm]);

  useEffect(() => {
    if (tab === 1) {
      setTitle("Select Client and Template");
    }
    if (tab === 2) {
      if(appName === "TP")
        setTitle("Select Tax Year")
      if(appName === "CS")
        setTitle("Select Dates");
    }
    if (tab === 3) {
      setTitle("Select Legal Entities");
    }
    if (tab === 4) {
      setTitle("Select Legal Entities Settings");
    }
    if (tab === 5) {
      setTitle("Customize site name");
    }
  }, [tab]);

  const onSelectionchanged = (e: any) => {
    setSelectedData(e.selectedRowsData);
  };
  const datetime = (e: any) => {
    if (e.data.created === null) {
      return null;
    } else {
      return (
        <div style={{ display: "flex" }}>
          <div>
            <div>{convertUTCTimeToLocal(e.data.created)}</div>
          </div>
        </div>
      );
    }
  };

  const cellRender = (e: any) => {
    if (e.data.created === null) {
      return null;
    } else {
      return (
        <div style={{ display: "flex" }}>
          <div>
            <div>{convertUTCTimeToLocal(e.data.archivedDate)}</div>
          </div>
        </div>
      );
    }
  };
  const cellRenderRestore = (e: any) => {
    return <div>{convertUTCTimeToLocal(e.data.restoredDate)}</div>;
  };
  const cellRenderForMessage = (e: any) => {
    if (e.data.message === null && e.data.status === null) {
      return null;
    }
    return <div title={e.data.message}>{e.data.status}</div>;
  };
  const SiteId = selectedData.map((e) => {
    return e.hqSiteId;
  });
  const refreshButtonOptions = {
    icon: "refresh",
    onClick: async () => {
      setSearchTerm(null);
      if (dataGridRef.current) {
        dataGridRef.current.instance.clearFilter();
      }
      fetchData();
    },
  };
  const onRemoveMapping = async () => {
    try {
      showSnackbar({ message: SnackbarMessage.REMOVE_MAPPING });
      var siteDetails: ArchiveSitesDTO = {
        userName: user?.profile.preferred_username as string,
        siteIds: SiteId,
      };
      var response = await taxPortalClientApi.taxPortal_ArchiveSites(
        siteDetails
      );
      if (response.isSuccess === true) {
        showSuccessSnackbar({
          message: SnackbarMessage.REMOVE_MAPPING_SUCCESS,
        });
      } else {
        showSnackbar({
          message: `${SnackbarMessage.REMOVE_MAPPING_FAIL} ${response.message}`,
          severity: "error",
        });
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const onUnarchive = async () => {
    try {
      showSnackbar({ message: SnackbarMessage.UNARCHIVE_SITE });
      var siteDetails: ArchiveSitesDTO = {
        userName: user?.profile.preferred_username as string,
        siteIds: SiteId,
      };
      var response = await taxPortalClientApi.taxPortal_UnarchiveSites(
        siteDetails
      );
      for (var i = 0; i < response?.length; i++) {
        var splitResponse = response[i].split(",");
        if (splitResponse[1] === "Site Exists") {
          showSnackbar({
            message: splitResponse[0] + SnackbarMessage.SITE_EXISTS,
          });
        } else if (response[i] === "Unarchived Successfully") {
          showSuccessSnackbar({ message: SnackbarMessage.UNARCHIVE_SUCCESS });
        } else if (splitResponse[1] === "Organization Exists") {
          await delay(2000);
          showSnackbar({
            message: splitResponse[0] + SnackbarMessage.ORGANIZATION_EXISTS,
          });
        } else {
          showSnackbar({
            message: SnackbarMessage.UNARCHIVE_FAIL,
            severity: "error",
          });
        }
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };
  const onAddClientId = async () => {
    try {
      showSnackbar({ message: SnackbarMessage.ADDCLIENTID_SITENAME });
      var response = await taxPortalClientApi.highQ_UpdateSiteNameWithClientId(
        SiteId
      );
      for (var i = 0; i < response?.length; i++) {
        if (response[i] === "ClientId Added Successfully") {
          showSuccessSnackbar({ message: SnackbarMessage.ADDCLIENTID_SUCCESS });
        } else {
          await delay(2000);
          showSnackbar({
            message: response[i],
            severity: "error",
          });
        }
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const onClickRetrySiteCreationWithSelection = async () => {
    try {
      showSuccessSnackbar({ message: SnackbarMessage.RETRY_FOR_SITE_CREATION });
      const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
      const messageForRetry = selectedData.map((e: any) => {
        return {
          siteId: 0,
          userName: user?.profile.preferred_username,
          siteName: e.name,
          clientName: e.clientName,
          mdmClientId: e.mdmClientId,
        };
      });
      if (messageForRetry != null) {
        var response = await taxPortalClientApi.siteSetUp_CreateSiteVerify(
          appName,
          messageForRetry
        );
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const uniqueTags = [];
  data?.data?.map((x) => {
    if (uniqueTags.indexOf(x.name) === -1) {
      uniqueTags.push(x.name);
    }
  });

  const onSelect = (e: string) => {
    switch (e) {
      case "ADD":
        setIsDisplayModal(true);
        break;
      case "Site Settings":
        setisDisplaySiteSettings(true);
        break;
      case "Legal Entities":
        setisDisplayLegalEntities(true);
        break;
      case "Select Dates":
        setisDisplayDates(true);
        break;
      case "Site Name":
        setIsDisplaySiteName(true);
        break;
    }
  };
  const onHistorySelect = (e: string) => {
    setIsHistoryOpen(true);
  };

  const SubmitSiteSettings = async () => {
    var response = await taxPortalClientApi.mDM_UpdateSelectedLegalEntities(
      editDataPermissionFinal,
      selectedData[0].hqSiteId
    );
    setisDisplaySiteSettings(false);
    sethandleEdit(false);
    setPopup(false);
    setLegalData({ gridLegalData: [] });
  };
  const handleClose = () => {
    setIsDisplayModal(false);
    setTab(1);
    setSelectedOption(null);
    setSelectionMode("multiple");
    setIsFlag(false);
    setsiteNameForIS("");
    setChecked(false);
    setisChecked(false);
    removeTaxYearForCS();
    removeTaxYear();
    localStorage.clear();
  };
  const handleCloseLegalEntites = () => {
    setisDisplayLegalEntities(false);
    setLegalData({ gridLegalData: [] });
    setSelectedOption(null);
    setSelectionMode("multiple");
    setIsFlag(false);
    sethandleEdit(false);
    setSelectionMode("multiple");
    localStorage.clear();
  };
  const handleCloseSiteSettings = () => {
    setisDisplaySiteSettings(false);
    setHandleRemoveSiteSettings(false);
    setLegalData({ gridLegalData: [] });
    setSelectedOption(null);
    sethandleEdit(false);
    localStorage.clear();
  };
  const handleCloseSiteName = () => {
    setIsDisplaySiteName(false);
    setLegalData({ gridLegalData: [] });
    setSelectedOption(null);
    sethandleEdit(false);
    localStorage.clear();
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setisChecked(e.target.checked);
  };
  const listData = [
    JSON.parse(`{
      "siteId":0,
      "userName":"${user?.profile.preferred_username as string}",
      "eventType": ${ServiceBusEnum.SITE_CREATION},
      "status":"INPROGRESS",
      "mdmClientId":"${selectedRowsData?.mdmClientId}",
      "templateId":"${selectedOption?.value}",
      "templateName":"${selectedOption?.label}",
      "siteName":"${dataPermissionFinal[0]?.hqClientName}",
      "clientName":"${selectedRowsData?.name}",
      "appName":"${appName !== null ? appName : "PERF"}",
      "siteConfig":{"startDate":${
        siteConfig?.startDate != undefined ? `"${siteConfig.startDate}"` : null
      }, 
                    "endDate":${
                      siteConfig?.endDate != undefined
                        ? `"${siteConfig.endDate}"`
                        : null
                    }},
      "isTestSite": ${isChecked},
      "year": ${appName === 'TP' ? taxYear : appName === 'CS' ? taxYearForCS : 0}
    }`),
  ];
  const listDataForIS = [
    JSON.parse(`{
      "siteId":0,
      "userName":"${user?.profile.preferred_username as string}",
      "eventType": ${ServiceBusEnum.SITE_CREATION},
      "status":"INPROGRESS",
      "mdmClientId":${0},
      "templateId":"${selectedOption?.value}",
      "templateName":"${selectedOption?.label}",
      "siteName":"${siteNameForIS}",
      "clientName":"Internal Client",
      "appName":"${appName !== null ? appName : "PERF"}",
      "siteConfig":{"startDate":${null},"endDate":${null}},
      "isTestSite": ${isChecked},
      "year": "${appName === 'TP' || appName ==='IS' ? taxYear : appName === 'CS' ? taxYearForCS : undefined}"
    }`),
  ];

  const handleContinueForIS = async () => {
    if (tab === 1) {
      setTab(tab + 1);
    }
    if (tab === 2) {
      setTab(tab + 1);
    }
    if (tab === 3 && appName === "IS") {
      try {
        const response = await taxPortalClientApi.taxPortal_CreateSites(
          listDataForIS
        );
        setIsSiteExistResponse(response);
        handleClose();
      } catch (error) {
        console.log(error, "error");
      }
    }
  };
 const handleContinue = async () => {
  if (tab === 5 && tab < 6) {
    if (selectionMode === "none") {
      try {
        const response = await taxPortalClientApi.taxPortal_CreateSites(
          listData
        );
        const isExists = !isNull(response) && !isEmpty(response);
        if (isExists) {
          setIsFlag(true);
          setIsSiteExistResponse(response);
        } else {
          setIsDisplayModal(false);
          handleClose();
          setIsFlag(false);
          setChecked(false);
          setisChecked(false);
          localStorage.clear();
        }
      } catch (error) {}
      localStorage.clear();
    } else {
      try {
        await taxPortalClientApi
          .mDM_AddSelectedLegalEntities(dataPermissionFinal)
          .then(async () => {
            const response = await taxPortalClientApi.taxPortal_CreateSites(
              listData
            );
            const isExists = !isNull(response) && !isEmpty(response);
            if (isExists) {
              setIsFlag(true);
              setIsSiteExistResponse(response);
            } else {
              setIsDisplayModal(false);
              handleClose();
              setIsFlag(false);
              setChecked(false);
              setisChecked(false);
              localStorage.clear();
            }
            localStorage.clear();
          });
      } catch (error) {}
    }
  } else if (tab === 1 || tab === 2) {
    setTab(tab + 1);
  } else if (tab === 3 || tab === 4) {
    if (selectionMode === "none") {
      if (appName === "TP") {
        setTab(tab + 2);
      } else if (appName === "CS") {
        setTab(tab + 2);
        if(tab === 4) setTab(tab + 1)
      }
    } else if (selectionMode === "multiple") {
      if (appName === "TP") {
        setTab(tab + 1);
      } else if (appName === "CS") {
        setTab(tab + 2);
      if(tab === 4) setTab(tab + 1)
      }
    }
  }
};
  const handleContinueLegalEntities = async () => {
    try {
      var response =
        await taxPortalClientApi.taxPortal_DisassociateLegalEntities(
          editDataPermissionFinal,
          selectedData[0].hqSiteId
        );
      setisDisplayLegalEntities(false);
      sethandleEdit(false);
      setLegalData({ gridLegalData: [] });
    } catch (error) {
      showSnackbar({
        message: SnackbarMessage.LEGAL_ENTITIES_EDIT_FAIL,
        severity: "error",
      });
    }
  };
  const handleContinueSiteSettings = async () => {
    var response = await taxPortalClientApi.mDM_CheckSettingsChange(
      editDataPermissionFinal,
      selectedData[0].hqSiteId
    );
    if (response === true) {
      setPopup(true);
    } else {
      SubmitSiteSettings();
    }
  };
  const handleContinueSiteName = async () => {
    try {
      console.log(selectedData[0].hqSiteId, "siteId")
      console.log(editDataPermissionFinal)
      var response = await taxPortalClientApi.highQ_ModifySiteName(
        selectedData[0].hqSiteId,
        editDataPermissionFinal
      );
      if (response === true) {
        showSuccessSnackbar({
          message: SnackbarMessage.SITE_NAME_EDIT_SUCCESS,
        });
      } else if (response === false) {
        showSnackbar({
          message:
            editDataPermissionFinal[0].hqClientName +
            SnackbarMessage.SITE_NAME_EXISTS,
          severity: "error",
        });
      }

      setIsDisplaySiteName(false);
      sethandleEdit(false);
      setLegalData({ gridLegalData: [] });
    } catch (error) {
      showSnackbar({
        message: SnackbarMessage.SITE_NAME_EDIT_FAIL,
        severity: "error",
      });
    }
  };
  const handleBack = () => {
    if (tab === 5 && selectionMode === "none") {
      setTab(tab - 2);
      setIsFlag(false);
    } else {
      if (appName === "TP")  setTab(tab - 1);
      else if (appName === "CS") {
      if (tab === 5) setTab(tab - 2);
       else setTab(tab - 1);
      }
      else setTab(tab - 1);
      setChecked(false);
      setisChecked(false);
    }
    if(tab === 3 && (appName === "TP" || appName === "CS")){
      setBtnContinueDisable(false);
    }
  };
  const handleEditLegalEntities = () => {
    sethandleEdit(true);
  };
  const handleNo = () => {
    setisDisplaySiteSettings(true);
    setPopup(false);
  };

  const handleYes = () => {
    try {
      SubmitSiteSettings();
    } catch (error) {
      showSnackbar({
        message: SnackbarMessage.SITE_SETTINGS_EDIT_FAIL,
        severity: "error",
      });
    }
    setisDisplaySiteSettings(false);
    setHandleRemoveSiteSettings(true);
    setPopup(false);
  };
  // const getFilteredAndSortedData = useCallback(() => {
  //   const grid = dataGridRef.current?.instance;
  //   const filterExpr = grid.getCombinedFilter(true);
  //   const dataSource = grid.getDataSource();
  //   const loadOptions = dataSource.loadOptions();

  //   dataSource
  //     .store()
  //     .load({
  //       filter: filterExpr,
  //       sort: loadOptions.sort,
  //       group: loadOptions.group,
  //     })
  //     .then((result) => {
  //       setDs(result);
  //     });
  // }, [dataGridRef]);
  //const searchPanelInputRef = useRef(null);
  const exportButtonOptions = {
    icon: "exportxlsx",
    onClick: async () => {
      try {
        var status =
          activeDiv === null
            ? "All"
            : activeDiv.charAt(0) + activeDiv.slice(1).toLowerCase();
        var response = await taxPortalClientApi
          .highQ_ExportToExcelForManageSite(appName, activeDiv)
          .then((res: any) => {
            const url = URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              appName + "-" + status + " Client Summary.xlsx"
            );
            link.click();
          });
        showSuccessSnackbar({
          message: SnackbarMessage.EXPORT_TO_EXCEL_SUCCESS,
        });
      } catch (error) {
        showSnackbar({
          message: SnackbarMessage.EXPORT_TO_EXCEL_FAIL,
          severity: "error",
        });
      }
    },
  };

  // const getFilteredAndSortedData = () => {
  //   const grid = dataGridRef.current?.instance;
  //   const filterExpr = grid.getCombinedFilter(true);
  //   const dataSource = grid.getDataSource();
  //   const loadOptions = dataSource.loadOptions();

  //   dataSource
  //     .store()
  //     .load({
  //       filter: filterExpr,
  //       sort: loadOptions.sort,
  //       group: loadOptions.group,
  //     })
  //     .then((result) => {
  //       setDs(result);
  //       setTimeout(() => {
  //         // Find the input field within the SearchPanel component
  //         const input = document.querySelector(
  //           ".dx-datagrid-search-panel input"
  //         );
  //         if (input) {
  //           input.focus();
  //         }
  //       }, 100);
  //     });
  // };

  const handleHistoryClose = () => {
    setIsHistoryOpen(false);
  };
  const recordExistResponse = SnackbarMessage.RECORD_EXISTS_POPUP;

  const cellRenderSiteName = (e: any) => {
    return (
      <>
        {" "}
        {e?.row?.data?.hqSiteId === 0 ? (
          <div>{e?.row?.data?.name}</div>
        ) : (
          <div
            style={{
              color: "#009cde",
              textDecoration: "underline",
              cursor: "pointer",
              display: "inline",
            }}
            onClick={() =>
              window.open(
                `${envProperties.reactAppHighQSiteUrl}=${e?.row?.data?.hqSiteId}`
              )
            }
          >
            {e?.row?.data?.name}
          </div>
        )}
      </>
    );
  };

  const cellRenderMasterClientId = (e: any) => {
    return (
      <div
        style={{
          color: "#009cde",
          textDecoration: "underline",
          cursor: "pointer",
          display: "inline",
        }}
        onClick={() =>
          window.open(
            `${envProperties.reactAppMdmClientIdURL}=${e?.row?.data?.mdmClientId}`
          )
        }
      >
        {e?.row?.data?.mdmClientId}
      </div>
    );
  };

  return (
    <div style={{ margin: "16px" }}>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          margin: "10px 0 10px 0",
        }}
      >
        <h2>Manage Sites</h2>
        <AppNameToggle />
      </div>
      <div style={{ marginRight: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0 10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "14vw",
              justifyContent: "space-between",
              margin: "10px 0 10px 0",
            }}
          >
            <div
              onClick={() => handleDivClick("ACTIVE")}
              style={{
                cursor: "pointer",
                color: activeDiv === "ACTIVE" ? "#fff" : "black",
                backgroundColor: activeDiv === "ACTIVE" ? "#009cde" : "#eee",
                borderRadius: "15px",
                padding: "5px 12px 5px 12px",
                transition: "background-color 0.3s, color 0.3s",
                marginLeft: "10px",
              }}
            >
              Active
            </div>
            <div
              onClick={() => handleDivClick("ARCHIVED")}
              style={{
                cursor: "pointer",
                color: activeDiv === "ARCHIVED" ? "#fff" : "black",
                backgroundColor: activeDiv === "ARCHIVED" ? "#009cde" : "#eee",
                borderRadius: "15px",
                padding: "5px 12px 5px 12px",
                transition: "background-color 0.3s, color 0.3s",
              }}
            >
              Archived
            </div>
            <div
              onClick={() => handleDivClick(null)}
              style={{
                cursor: "pointer",
                color: activeDiv === null ? "#fff" : "black",
                backgroundColor: activeDiv === null ? "#009cde" : "#eee",
                borderRadius: "15px",
                padding: "5px 12px 5px 12px",
                transition: "background-color 0.3s, color 0.3s",
              }}
            >
              All
            </div>
          </div>
          <Button
            className=""
            disabled={selectedData.length === 1 ? false : true}
            variant="outlined"
            sx={{ height: 35, marginBottom: "10px" }}
            onClick={() => {
              onHistorySelect();
            }}
          >
            History
          </Button>
        </div>
        <DialogBox
          isDisplayModal={isHistoryOpen}
          handleClose={handleHistoryClose}
          title={"Site History"}
          btnContinueDisable={btnContinueDisable}
        >
          <SiteHistory selectedRowData={selectedData[0]}></SiteHistory>
        </DialogBox>
        {appName === "IS" ? (
          <DialogBox
            isDisplayModal={isDisplayModal}
            handleClose={handleClose}
            btnOpenText={tab === 3 ? "Finish" : "Next"}
            title={"Select Internal Site Template"}
            handleContinue={handleContinueForIS}
            btnBackText={tab !== 1 ? "Back" : ""}
            handleBack={handleBack}
            btnContinueDisable={btnContinueDisable}
            isCustomScreen={checked}
            handleChange={handleChange}
          >
            <SelectClientAndTemplateForIS
              tab={tab}
              setSelectedRowsData={setSelectedRowsData}
              selectedOption={selectedOption}
              selectedRowsData={selectedRowsData}
              setSelectedOption={setSelectedOption}
              setBtnContinueDisable={setBtnContinueDisable}
              setDataPermissionFinal={setDataPermissionFinal}
              selectionMode={selectionMode}
              setSelectionMode={setSelectionMode}
              appName={appName}
              setIsFlag={setIsFlag}
              setSiteConfig={setSiteConfig}
              siteConfig={siteConfig}
              siteNameForIS={siteNameForIS}
              setsiteNameForIS={setsiteNameForIS}
              setChecked={setChecked}
            />
          </DialogBox>
        ) : (
          <DialogBox
            isDisplayModal={isDisplayModal}
            handleClose={handleClose}
            btnOpenText={tab === 5 ? "Finish" : "Next"}
            title={title}
            handleContinue={handleContinue}
            btnBackText={tab !== 1 ? "Back" : ""}
            handleBack={handleBack}
            btnContinueDisable={btnContinueDisable}
            isCustomScreen={checked}
            handleChange={handleChange}
          >
            <SelectClientAndTemplate
              tab={tab}
              setSelectedRowsData={setSelectedRowsData}
              selectedOption={selectedOption}
              selectedRowsData={selectedRowsData}
              setSelectedOption={setSelectedOption}
              setBtnContinueDisable={setBtnContinueDisable}
              setDataPermissionFinal={setDataPermissionFinal}
              selectionMode={selectionMode}
              setSelectionMode={setSelectionMode}
              appName={appName}
              setIsFlag={setIsFlag}
              setSiteConfig={setSiteConfig}
              setChecked={setChecked}
              siteConfig={siteConfig}
            />
          </DialogBox>
        )}
        <DialogBox
          isDisplayModal={isDisplayLegalEntities}
          handleClose={handleCloseLegalEntites}
          handleEdit={handleEditLegalEntities}
          btnOpenText={"Save"}
          title={"Select Legal Entities"}
          btnEditText={"Edit"}
          handleContinue={handleContinueLegalEntities}
          btnContinueDisable={btnContinueDisable}
        >
          <SelectEditLegalEntities
            selectedRowsData={selectedData}
            setLegalData={setLegalData}
            legalData={legalData}
            setBtnContinueDisable={setBtnContinueDisable}
            selectedLegalEntityData={selectedLegalEntityData}
            setSelectedLegalEntityData={setSelectedLegalEntityData}
            setSelectionMode={setSelectionMode}
            selectionMode={selectionMode}
            handleEdit={handleEdit}
            setDataPermission={seteditDataPermissionFinal}
            dataPermission={editDataPermissionFinal}
          />
        </DialogBox>
        <DialogBox
          isDisplayModal={isDisplaySiteSettings}
          handleClose={handleCloseSiteSettings}
          handleEdit={handleEditLegalEntities}
          btnEditText={"Edit"}
          handleContinue={handleContinueSiteSettings}
          btnOpenText={"Save"}
          title={"Select Legal Entities Settings"}
          btnContinueDisable={btnContinueDisable}
        >
          <LegalEntitesEditPermission
            setDataPermission={seteditDataPermissionFinal}
            handleEdit={handleEdit}
            dataPermission={editDataPermissionFinal}
            selectedRowsData={selectedData}
          />
        </DialogBox>
        <DialogBox
          isDisplayModal={isDisplaySiteName}
          handleClose={handleCloseSiteName}
          handleEdit={handleEditLegalEntities}
          btnEditText={"Edit"}
          handleContinue={handleContinueSiteName}
          btnOpenText={"Save"}
          title={"Customize site name"}
          btnContinueDisable={btnContinueDisable}
        >
          <ModifySiteName
            selectedRowsData={selectedData}
            dataPermission={editDataPermissionFinal}
            setDataPermission={seteditDataPermissionFinal}
            handleEdit={handleEdit}
          />
        </DialogBox>
        <Dialog
          open={popup}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <closeicon />
          </IconButton>
          <div style={{ marginTop: "30px" }}>
            <DialogContent>
              <Typography style={{ whiteSpace: "pre-line" }}>
                {recordExistResponse}
              </Typography>
            </DialogContent>
          </div>
          <DialogActions>
            <Button
              onClick={handleNo}
              style={{ margin: "0 16px 0 0" }}
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={handleYes}
              style={{ margin: "0 16px 0 0" }}
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <DataGrid
          ref={dataGridRef}
          allowColumnResizing={true}
          dataSource={data?.data}
          showBorders={true}
          onSelectionChanged={onSelectionchanged}
          height={calculateGridHeight(260)}
          remoteOperations={true}
          noDataText={data.pending ? "Loading..." : "No Data"}
          btnContinueDisable={btnContinueDisable}
        >
          <Selection
            mode="multiple"
            selectAllMode={"page"}
            showCheckBoxesMode={"always"}
          />
          <Paging enabled={false} />
          <Scrolling columnRenderingMode="virtual" />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <SearchPanel
            visible={true}
            placeholder="Global Search..."
            onTextChange={(e: any) => {
              setSearchTerm(e);
            }}
          />
          <Toolbar>
            <Item location="after">
              <Button
                onClick={onClickRetrySiteCreationWithSelection}
                variant="outlined"
              >
                retry
              </Button>
            </Item>
            <Item location="after">
              <SplitButton
                onSelect={onSelect}
                options={[
                  "ADD",
                  "Legal Entities",
                  "Site Settings",
                  "Site Name",
                ]}
                selection={selectedData?.length}
              ></SplitButton>
            </Item>
            <Item location="after">
              <Button
                variant="outlined"
                onClick={onRemoveMapping}
                disabled={selectedData.length > 0 ? false : true}
              >
                Mark as Archived
              </Button>
            </Item>
            <Item location="after">
              <Button
                variant="outlined"
                onClick={onUnarchive}
                disabled={selectedData.length > 0 ? false : true}
              >
                Unarchive
              </Button>
            </Item>
            <Item location="after">
              <Button
                variant="outlined"
                onClick={onAddClientId}
                disabled={selectedData.length > 0 ? false : true}
              >
                Update SiteName
              </Button>
            </Item>
            {/* <Item location="before">
              <div>
                <h4>
                  {selectedData?.length} out of{" "}
                  {ds?.length > 0 ? ds?.length : data.totalCount} records
                  selected
                </h4>
              </div>
            </Item> */}
            <Item
              widget="dxButton"
              options={exportButtonOptions}
              name="alignRight"
            />
            <Item name="searchPanel" location="after" />
            <Item
              widget="dxButton"
              options={refreshButtonOptions}
              name="alignRight"
            />
          </Toolbar>
          <Column
            dataField="hqSiteId"
            caption="SiteID"
            minWidth={70}
            alignment="left"
          ></Column>
          <Column
            dataField="orgId"
            caption="Organisation ID "
            minWidth={135}
            alignment="left"
          ></Column>
          <Column
            dataField="mdmClientId"
            caption="Master ClientID"
            minWidth={135}
            alignment="left"
            cellRender={cellRenderMasterClientId}
          ></Column>
          <Column
            dataField="mdmClientId"
            caption="ClientID"
            minWidth={100}
            alignment="left"
          ></Column>
          <Column
            minWidth={300}
            dataField="name"
            caption="Site Name"
            alignment="left"
            cellRender={cellRenderSiteName}
          ></Column>
          <Column
            minWidth={300}
            dataField="templateName"
            caption="Site Template"
            alignment="left"
          ></Column>
          <Column
            cellRender={cellRenderForMessage}
            dataField="status"
            caption="Status"
            minWidth={100}
            alignment="left"
          ></Column>
          <Column
            dataField="createdBy"
            caption="Created by "
            minWidth={180}
            alignment="left"
          ></Column>
          <Column
            cellRender={datetime}
            dataField="Created Date"
            caption="Created Date "
            minWidth={180}
            alignment="left"
          ></Column>
          <Column
            dataField="archivedBy"
            caption="Archived by"
            minWidth={180}
            alignment="left"
          ></Column>
          <Column
            cellRender={cellRender}
            dataField="Archived Date"
            caption="Archived Date "
            minWidth={180}
            alignment="left"
          ></Column>
          <Column
            dataField="restoredBy"
            caption="Restored by"
            minWidth={180}
            alignment="left"
          ></Column>
          <Column
            cellRender={cellRenderRestore}
            dataField="Restored Date"
            caption="Restored Date "
            minWidth={180}
            alignment="left"
          ></Column>
        </DataGrid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "5px 0 0 0",
          }}
        >
          <Pagination
            onChange={(e: any, pageNumber: any) => setpageNumber(pageNumber)}
            count={Math.ceil(data.totalCount / defaultPageSize)}
            variant="outlined"
            shape="rounded"
            page={pageNumber}
          />
        </div>
      </div>
    </div>
  );
}
