import { env } from "@rsmus/react-env-image";

export const envProperties = {
  WebBaseUrl: env("REACT_APP_WEBBASE_URL"),
  ApiBackendUrl: env("REACT_APP_BASE_API_URL"),
  CemBaseUrl: env("REACT_APP_CEMBASE_URL"),
  reactAppHighQUrl: env("REACT_APP_HIGHQ_URL"),
  reactAppMdmClientIdURL: env("REACT_APP_MDM_CLIENT_ID_URL"),
  reactAppHighQSiteUrl: env("REACT_APP_HIGHQ_SITE_URL"),
  authentication: {
    clientId: env("REACT_APP_AUTH_CLIENTID"),
    authority: env("REACT_APP_AUTH_AUTHORITY"),
  },
  RolesFlag: env("RSM_APP_FEATURE_ROLE"),
  highQLoginUrl:env("REACT_APP_HIGHQ_LOGIN_URL")
};
