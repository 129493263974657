import { useContext, useEffect, useState } from "react";
import { models, Report, Embed } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { ExternalUserSite, PowerBIReport } from "../../api-client-nswag/taxportal-client";
import { getNewAccessToken, timeOutForRefreshToken } from "../../util/Utils";
import React from "react";
import "./SitesBreakDown.scss";
import { Item } from "devextreme-react/data-grid";
import { SnackbarMessage, useSnackbar } from "../../hooks";
import { Button } from "devextreme-react";

export const SitesBreakdownPBI = (props: any) => {
  const [report, setReport] = useState<Report | null>(null);
  const [flag, setFlag] = useState<boolean | null>(null);
  const [tokenTimeout, setTokenTimeout] = useState<any>(null);
  const [tokenExpiration, setTokenExpiration] = useState<Date | null>(null);
  const { showSnackbar, showSuccessSnackbar } = useSnackbar();

  const [reportConfigDTO, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: "report",
    embedUrl: "",
    tokenType: models.TokenType.Embed,
    accessToken: "",
    settings: undefined,
  });

  const reportContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "94vw",
    height: "130vh",
    //padding: '110px 0px 0px 0px'
  };

  useEffect(() => {
    loadReportConfig();
  }, []);

  const loadReportConfig = async () => {
    await taxPortalClientApi
      .reports_GetSitesBreakdown(PowerBIReport.SITES_BREAKDOWN)
      .then((res) => {
        const settings = {
          type: "report",
          embedUrl: res?.embedUrl,
          accessToken: res?.token,
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: false,
          },
        };

        setReportConfig(settings);
        setFlag(false);
        const expiresIn = new Date(res.expiration as Date);
        expiresIn.setMinutes(expiresIn.getMinutes() - 2);
        setTokenExpiration(expiresIn);
      })
      .catch((err) => {
        if (err.status === 401) {
          setFlag(true);
        }
      });
  };
  const exportButtonOptions = async () => {
    try {
      var response = await taxPortalClientApi
        .highQ_ExportToExcelForSiteMetrics(props.appName, props.exportData)
        .then((res: any) => {
          const url = URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Site Creation Summary.xlsx");
          link.click();
        });
      showSuccessSnackbar({ message: SnackbarMessage.EXPORT_TO_EXCEL_SUCCESS });
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.EXPORT_TO_EXCEL_FAIL, severity: "error" });
    }
  };

  const refreshAccessToken = async () => {
    if (report) {
      const res = await getNewAccessToken(PowerBIReport.CS_PROJECT_TRACKER_LITE);
      if (res && res?.token !== null) {
        const newToken = res.token as string;
        report.setAccessToken(newToken);

        const expiresIn = new Date();
        expiresIn.setHours(expiresIn.getHours() + 1);
        setTokenExpiration(expiresIn);

        if (tokenTimeout) {
          clearTimeout(tokenTimeout);
        }

        const currentTime = new Date();
        const refreshTime = expiresIn.getTime() - currentTime.getTime() - 2 * 60 * 1000;
        if (refreshTime > 0) {
          setTokenTimeout(setTimeout(refreshAccessToken, refreshTime));
        }
      }
    } else {
      console.error("Failed to refresh token.");
    }
  };

  useEffect(() => {
    timeOutForRefreshToken(tokenExpiration, setTokenTimeout, refreshAccessToken);
    return () => {
      if (tokenTimeout) {
        clearTimeout(tokenTimeout);
      }
    };
  }, [report, tokenExpiration]);

  return (
    <>
      {flag === true ? null : (
        <div style={reportContainerStyle}>
          <PowerBIEmbed
            embedConfig={reportConfigDTO}
            cssClassName="embed-container"
            getEmbeddedComponent={(embedObject: Embed) => {
              setReport(embedObject as Report);
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 380,
              left: 20,
              cursor: "pointer",
            }}
          >
            {reportConfigDTO.settings !== undefined && (
              <>
                <Button hint="Export to Excel" icon="exportxlsx" onClick={exportButtonOptions} />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
