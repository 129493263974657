import { FilesInspection, MoveIManageFiles } from "../../../api-client-nswag/taxportal-client";
import { COLORS } from "./ModalCopyFile.types";

export const parseNewName = (file: MoveIManageFiles) => {
  const newNameExt = file.newFileName?.split(".");
  newNameExt?.pop();
  const newName = newNameExt?.join(".");
  return newName;
};

export const iconSelected = (color: boolean): any => (color ? COLORS.ACTIVE : COLORS.DEFAULT);

const updateEditedState = (file: MoveIManageFiles, prevState: FilesInspection, isFileEdited: boolean = false) => {
  const isEdited = !isFileEdited && file.isEdited!;

  return prevState?.duplicated?.map((item) => {
    if (item.documentId === (file?.documentId || null)) {
      return isEdited
        ? { ...item, newFileName: "", overWrite: false, isEdited: false, isOmitted: false }
        : { ...item, overWrite: false, isEdited: true, isOmitted: false };
    }
    return item;
  });
};

export const updatedState = (file: MoveIManageFiles, prevState: FilesInspection, isBlurEnabled: boolean) => ({
  ...prevState,
  duplicated: updateEditedState(file, prevState, isBlurEnabled),
});
