import { useContext, useEffect, useRef, useState } from "react";
import { OidcIdentityContext } from "@rsmus/react-auth";

import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Paging,
  Toolbar,
  Item,
  MasterDetail,
  Selection,
} from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";

import { dataGridConfig } from "../../../constants";
import { calculateGridHeight, executeDownload } from "../../../util/Utils";
import useSnackbar from "../../../hooks/useSnackbar";
import { SnackbarMessage } from "../../../hooks/useSnackbar.types";

import { SitesChangesDetail } from "./SitesChangesDetail";
import { SitesSelectedProps, constants, manageSitesSelected } from "./UpgradeSitesGrids.types";
import { CellRenderDate } from "../../../util/cellRendersAtoms";
import { HQSiteChangesDTO, HQSiteDetailsDTO } from "../../../api-client-nswag/taxportal-client";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { upsertData } from "./atoms/actions";

type DatagridRefType = DataGrid;
const {
  BUTTON_CONFIRM,
  BUTTON_BACK,
  BUTTON_CANCEL,
  SITES_SELECTED_TITLE,
  COLUMNS,
  BUTTON_DOWNLOAD_EXCEL,
  FILE_COMPARISON_NAME,
  EXCEL_FILE_EXTENSION,
  ALERT_MESSAGE,
} = constants;
const {
  HQ_SITE_ID,
  ORG_ID,
  MDM_MASTER_CLIENT_ID,
  MDM_CLIENT_ID,
  NAME,
  TEMPLATE_NAME_OLD,
  TEMPLATE_NAME_NEW,
  UPGRADE_DATE,
  UPGRADED_BY,
  STATUS,
} = COLUMNS;

const initialStateData = { pending: true, sites: [] };

export const SitesSelected = ({
  selectedSitesData,
  selectedSites,
  setSelectedSites,
  templateSelected,
  toggleSitesGrids,
}: SitesSelectedProps) => {
  const { user } = useContext(OidcIdentityContext);
  const userName = user?.profile.preferred_username!;
  const sitesGridRef = useRef<DatagridRefType>(null);
  const [data, setData] = useState<manageSitesSelected>(initialStateData);
  const [selectedChanges, setSelectedChanges] = useState<HQSiteChangesDTO[]>([]);
  const [selectedToDownload, setSelectedToDownload] = useState<HQSiteChangesDTO[]>([]);
  const [hideBackButton, setHideBackButton] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [changesWStatus, setChangesWStatus] = useState<HQSiteChangesDTO[]>([]);
  const [changesDeployed, setChangesDeployed] = useState<number>(0);
  const { showSnackbar } = useSnackbar();
  const isAllowApplyChanges = selectedChanges.length && selectedChanges.length <= changesDeployed ? true : false;

  const fetchData = () => {
    setData({ pending: false, sites: selectedSitesData });
    setSelectedSites([]);
  };

  const goBackAction = () => {
    if (!hideBackButton) setSelectedSites([]);
    toggleSitesGrids(true);
  };

  const handleApplyChanges = () => {
    showSnackbar({ message: SnackbarMessage.PROCEED_TO_UPGRADE });
    toggleAtionButtons(false);
    setIsPending(true);
    const dataToSend = setDataToUpgrade();
    for (const data of dataToSend) {
      executeUpgrade([data]);
    }
  };

  const executeUpgrade = async (dataToSend: HQSiteChangesDTO[]) => {
    try {
      const resultChanges = await taxPortalClientApi.taxPortal_ProceedToUpgradeSites(
        templateSelected,
        dataToSend,
        userName
      );
      await setChangesWStatus(resultChanges);
      setIsPending(false);
      sitesGridRef.current!.instance.deselectRows([dataToSend[0].siteId]);
      toggleAtionButtons(true);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const modifySelectedChanges = (newData: HQSiteChangesDTO, isToDownload = false) => {
    const method = !isToDownload ? setSelectedChanges : setSelectedToDownload;
    executeModifiySelected(method, newData);
  };

  const executeModifiySelected = (
    setterMethod: (value: React.SetStateAction<HQSiteChangesDTO[]>) => void,
    newData: HQSiteChangesDTO
  ) => {
    setterMethod((prevChanges) => {
      const newChangesData = [...prevChanges];
      return upsertData(newChangesData, newData, "siteId");
    });
  };

  const removingSelectedChanges = (rowKey: number) => {
    removeSelected(setSelectedChanges, rowKey);
    removeSelected(setSelectedToDownload, rowKey);
  };

  const removeSelected = (method: (value: React.SetStateAction<HQSiteChangesDTO[]>) => void, rowKey: number) => {
    method((prevChanges) => {
      const newChangesData = [...prevChanges];
      return newChangesData.filter((change: HQSiteChangesDTO) => change.siteId !== rowKey);
    });
  };

  const setDataToUpgrade = () => {
    const existingIds = new Set(selectedChanges.map((change) => change.siteId));

    const missingObjects: HQSiteChangesDTO[] = selectedSites
      .filter((siteId) => !existingIds.has(siteId))
      .map((siteId) => ({ siteId, siteChanges: [] }));

    return [...selectedChanges, ...missingObjects];
  };

  const renderDetail = (props: any) => {
    const parentKey = props.key;
    const parentComponent = props.component;
    return (
      <SitesChangesDetail
        parentKey={parentKey}
        parentComponent={parentComponent}
        selectedSites={selectedSites}
        selectedChanges={selectedChanges}
        templateSelected={templateSelected}
        changesWStatus={changesWStatus}
        isPending={isPending}
        modifySelectedChanges={modifySelectedChanges}
        removingSelectedChanges={removingSelectedChanges}
      />
    );
  };

  const handleExportButton = async () => {
    if (!selectedToDownload) return;
    showSnackbar({ message: SnackbarMessage.COMPARISON_EXCEL_DOWNLOAD_MESSAGE });
    selectedToDownload.forEach((siteChanges) => {
      getExportedFile(siteChanges);
    });
  };

  const getExportedFile = async (siteChanges: HQSiteChangesDTO) => {
    try {
      const base64String = await taxPortalClientApi.HQTemplateSiteExcelExport(siteChanges, false);
      if (base64String)
        executeDownload(base64String, String(siteChanges.siteId), FILE_COMPARISON_NAME, EXCEL_FILE_EXTENSION);
    } catch (error) {
      showSnackbar({
        message: SnackbarMessage.DOWNLOAD_FAILED.replace("%site%", String(siteChanges.siteId)) as SnackbarMessage,
        severity: "error",
      });
    }
  };

  const onSelectionchanged = ({ selectedRowKeys }: SelectionChangedEvent) => {
    setSelectedSites(selectedRowKeys);
  };

  const toggleAtionButtons = (hideButtons: boolean) => {
    setHideBackButton(hideButtons);
  };

  useEffect(() => {
    setChangesDeployed(selectedChanges.filter((item) => item.siteChanges && item.siteChanges.length).length);
  }, [selectedChanges]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div>
        <h4>{SITES_SELECTED_TITLE}</h4>
      </div>
      <DataGrid
        id="grid-selected"
        ref={sitesGridRef}
        keyExpr="hqSiteId"
        height={calculateGridHeight(290)}
        {...dataGridConfig}
        dataSource={data.sites}
        noDataText={data.pending ? "Loading..." : "No data"}
        onSelectionChanged={onSelectionchanged}
        selectedRowKeys={selectedSites}
      >
        <Selection mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
        <Scrolling mode="standard" />
        <LoadPanel enabled={false} />
        <SearchPanel visible />
        <FilterRow visible />
        <HeaderFilter visible />
        <Paging enabled defaultPageSize={20} />
        <Toolbar>
          <Item location="after">
            <Button
              variant="outlined"
              disabled={!isAllowApplyChanges}
              onClick={() => {
                handleExportButton();
              }}
            >
              {BUTTON_DOWNLOAD_EXCEL}
            </Button>
          </Item>
          <Item name="searchPanel" location="after" />
        </Toolbar>
        <Column dataField="hqSiteId" caption={HQ_SITE_ID} minWidth={70} alignment="left" />
        <Column dataField="orgId" caption={ORG_ID} minWidth={135} alignment="left" />
        <Column dataField="mdmMasterClientId" caption={MDM_MASTER_CLIENT_ID} minWidth={135} alignment="left" />
        <Column dataField="mdmClientId" caption={MDM_CLIENT_ID} minWidth={100} alignment="left" />
        <Column minWidth={300} dataField="name" caption={NAME} alignment="left" />
        <Column minWidth={300} dataField="templateNameOld" caption={TEMPLATE_NAME_OLD} alignment="left" />
        <Column minWidth={300} dataField="templateNameNew" caption={TEMPLATE_NAME_NEW} alignment="left" />
        <Column
          cellRender={({ data }: { data: HQSiteDetailsDTO }) => CellRenderDate(data.upgradeDate!)}
          dataField="upgradeDate"
          caption={UPGRADE_DATE}
          minWidth={180}
          alignment="left"
        />
        <Column dataField="upgradeBy" caption={UPGRADED_BY} minWidth={180} alignment="left" />
        <Column dataField="status" caption={STATUS} minWidth={180} alignment="left" />
        <MasterDetail enabled render={renderDetail} />
      </DataGrid>

      <div className="footer-buttons-container">
        <div className="alert-msg alert-text">
          <WarningIcon />
          {ALERT_MESSAGE}
        </div>
        <div className="button-group">
          <Button className="footer-button" onClick={goBackAction} variant="outlined">
            {hideBackButton ? BUTTON_CANCEL : BUTTON_BACK}
          </Button>
          {hideBackButton ? (
            <Button
              className="footer-button"
              disabled={!isAllowApplyChanges}
              onClick={handleApplyChanges}
              variant="outlined"
            >
              {BUTTON_CONFIRM}
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};
