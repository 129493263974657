//@ts-nocheck
import React, { useCallback, useEffect, useState, useRef } from 'react';
import DataGrid, {
  Column,
  Selection,
  SearchPanel,
  Item,
  Toolbar
} from 'devextreme-react/data-grid';
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from "recoil";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { dataGridConfig } from '../../../constants';
import { SelectBox } from 'devextreme-react';
import { Link } from 'react-router-dom';
import { taxPortalClientApi } from '../../../api-client-nswag/taxportal-client-runtime';

export const clientData = atom ({
  key: 'clientData',
  default: []
});

export const siteTemplates = atom ({
  key: 'siteTemplates',
  default: ''
});

export const siteTemplateId = atom ({
  key: 'siteTemplateId',
  default: ''
});


export const AddClients = () => {
  const [data, setData] = useState({ pending: true, fulfilled: false, data: [] });
  const [clients, setClients] = useState()
  const [selection,setSelection] = useRecoilState(clientData);
  const [tempSelection,setTempSelection] = useState(false);
  const [siteTemplate, setsiteTemplate] = useState({ pending: true, fulfilled: false, siteTemplate: [] });
  const [selectedState, setSelectedState] = useRecoilState(siteTemplates);
  const [templateIds, setTemplateIds] = useRecoilState(siteTemplateId)
  const [search,setsearch] = useState('');
  const [disable, setDisable] = useState(true);
  const navigate = useNavigate()
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        setsiteTemplate({pending:true, fulfilled:false, siteTemplate:[]})
        const data = await taxPortalClientApi.highQ_GetHQSiteTemplate();
        setsiteTemplate({pending:false, fulfilled:true, siteTemplate:data})
      } catch (error) {
        console.log(error,"error")
      };      
    }
    fetchData()
  },[]);

  useEffect(() => {
    if (search!==null && search.length > 0)
    {
    const fetchData = async () => {
      try {
        setData({ pending: true, fulfilled: false, data: [] })
        const data = await taxPortalClientApi.cEM_CEMSearchClients(search);   
        setData({ pending: false, fulfilled: true, data: data }) 
        setClients(data)     
      } catch (error) {
        // Handle error
      };
    }
    fetchData();
    
  }
  }, [search]);
 
  useEffect(() => { 
    if (selection?.length > 0 && selectedState?.length>0)
    {
      setDisable(false)
    }
    },[selection,selectedState]);

       
    useEffect (() => {
     
      const search2 = JSON.parse(localStorage.getItem('search'))
      if (search2?.length>1)
      {
        if(search2[search2.length-1] !== search)
        {
          setTempSelection(true)
        }
      }
    },[search])


    const onSelectionChanged = (e : any) => { 
      
      var Items1 = JSON.parse(localStorage.getItem('items'))
      if (Items1===null){
        Items1=[];
        var items2 = Items1.concat(e.currentSelectedRowKeys)
      }
      else{      
      var items2 = Items1.concat(e.currentSelectedRowKeys)
      }
      
      localStorage.setItem('items',JSON.stringify(items2));
      var jsonObject = items2?.map(JSON.stringify);
      var uniqueSet = new Set(jsonObject);
      var uniqueItems2 = Array.from(uniqueSet)?.map(JSON.parse);
      setSelection(uniqueItems2)

      if (e.currentDeselectedRowKeys.length >0 && tempSelection===false){
         const items4 = JSON.parse(localStorage.getItem('items'));
        var arr = items4.findIndex(x => x.mdmClientId === e.currentDeselectedRowKeys[0].mdmClientId);
        items4.splice(arr,1)
        localStorage.setItem('items',JSON.stringify(items4))
        var jsonObject = items4?.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueItems4 = Array.from(uniqueSet)?.map(JSON.parse);
        setSelection(uniqueItems4)
      }
      var Items = JSON.parse(localStorage.getItem('search'))
    
      if (Items===null){
        Items=[];
        var items3 = Items.concat(search)
      }
      else {     
      var items3 = Items.concat(search)
      }
      localStorage.setItem('search',JSON.stringify(items3))
      setTempSelection(false)   
  }

  const deleteStorage = () =>{
    localStorage.clear()

  }

  const onValueChanged = ((e:any) => {
    setSelectedState(e.value);
    const index = (element) => element === e.value;
    var arr1 = templateName.findIndex(index);
    setTemplateIds(templateId[arr1])
  })

  const templateId = siteTemplate.siteTemplate?.map ((e) => {
    return e.id
  })
  const templateName = siteTemplate.siteTemplate?.map ((e) => {
    return e.sitename
  })

    return (
      <>
      <div style={{ display: "flex", justifyContent: "flex-end" , marginTop:"20px"}}>
        <Link to="/clients">
        <Button variant="outlined"
          sx={{ marginBottom: "-27px", marginRight: "20px" }}
          onClick={deleteStorage}
          className='button1' disabled={disable}>Add to List</Button>
          </Link>
      </div>
      
      <div style={{marginLeft:"20px",marginRight:"20px"}}>
      <SelectBox 
      dataSource={templateName} 
      onValueChanged={onValueChanged}
      placeholder='Select a Template...'
      noDataText={siteTemplate.pending ? 'Loading...' : 'No Data'}
      items={templateName}
      width={300}/>
   
        <DataGrid  
          id="grid-container"
          dataSource={clients}
          onSelectionChanged={onSelectionChanged} 
          noDataText={(search==='')? "" :(data.pending ? 'Loading...' : 'No Data') }
          {...dataGridConfig}   
        > 
          <SearchPanel visible={true}
            width={240}
            placeholder="Search..." 
            onTextChange={(e:any)=>{
              setsearch(e)
            }}
            />
          <Selection mode="multiple" 
           showCheckBoxesMode='always'/>
          <Column dataField="mdmMasterClientId" caption="Master ClientID" alignment= "left"/>
          <Column dataField="mdmClientId" caption="ClientID" alignment= "left"/>
          <Column dataField="name" caption="Client Name" alignment= "left"/>

        </DataGrid>
        </div>
        </>
  );
};
