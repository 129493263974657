import {
  Checkbox,
  CheckboxProps,
  Radio,
  RadioProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";

import { SelectorsProps } from "./SelectorsHQ.types";
import "./SelectorsHQ.scss";
import { ServiceBusEnum } from "../../../api-client-nswag/taxportal-client";

export default function SelectorsHQ({ title, options, setCheckboxValues, radio }: SelectorsProps) {
  const onChange = (id: ServiceBusEnum) =>
    setCheckboxValues((checkboxes) =>
      checkboxes.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : radio ? { ...item, checked: false } : item
      )
    );

  return (
    <FormControl component="fieldset">
      <FormLabel component="h2" className="hq-title">
        {title}
      </FormLabel>
      <FormGroup aria-label="position" row>
        {options.map(({ id, text, checked }) => {
          const commonProps: RadioProps | CheckboxProps = {
            checked: checked,
            onChange: () => onChange(id),
            name: text,
            className: "hq__checkbox",
          };
          return (
            <FormControlLabel
              key={id}
              control={
                radio ? (
                  <Radio {...commonProps} color="default" size="small" />
                ) : (
                  <Checkbox {...commonProps} color="default" size="small" />
                )
              }
              label={text}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
