import applyChanges from "devextreme/data/apply_changes";

import {
  FETCH_SUCCESS,
  SAVING_CANCEL,
  SAVING_ERROR,
  SAVING_SUCCESS,
  UPDATE_CHANGES,
  SET_EDIT_ROW_KEY,
  SHOW_LOADING,
} from "../ScheduleFiles.types";
import { ActionTypes, InitialScheduleStateProps } from "../ScheduleFiles.types";
import { GetScheduleFiles } from "../../../api-client-nswag/taxportal-client";

export function reducer(state: InitialScheduleStateProps, action: ActionTypes): InitialScheduleStateProps {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };

    case SAVING_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case SET_EDIT_ROW_KEY:
      return {
        ...state,
        editRowKey: action.payload,
      };

    case SAVING_CANCEL:
      return {
        ...state,
        changes: [],
        editRowKey: null,
        editRow: null,
        isLoading: false,
      };

    case UPDATE_CHANGES:
      const id = action?.payload[0]?.key;
      const selectedRow: GetScheduleFiles = state.data.filter((schedule) => schedule.id === id)[0];

      return {
        ...state,
        changes: action.payload,
        editRow: selectedRow,
      };

    case SAVING_SUCCESS:
      const newData = applyChanges(state.data, [action.payload.change], {
        keyExpr: "id",
      });

      return {
        ...state,
        data: newData,
        changes: [],
        editRowKey: null,
        editRow: null,
        isLoading: false,
      };

    default:
      return state;
  }
}
