import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalProps } from "../../cui/components/Modal/Modal.types";

export const DialogBox = ({
  title = "",
  btnOpenText = "",
  btnCloseText = "Cancel",
  btnBackText = "",
  btnEditText = "",
  btnCloseTop = true,
  maxWidth = "md",
  isDisplayModal,
  isCustomScreen,
  handleContinue,
  handleClose,
  handleBack,
  handleEdit,
  handleChange,
  children,
  btnContinueDisable
}: ModalProps) => {
  return (
    <>
      <Dialog
        open={isDisplayModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={maxWidth}
        className="cui-c_modal"
      >
        <DialogTitle id="alert-dialog-title" className="cui-c_modal-title">
          {title}
        </DialogTitle>
        {btnCloseTop ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <DialogContent style={{padding:"0 16px 10px 16px"}}>{children}</DialogContent>
        <DialogActions className="cui-c_modal-footer">
          <div 
          style={{display:'flex',justifyContent:!!btnBackText?.length?'space-between':"flex-end", width:'100%', alignItems:'center'}}
          >

           {!!btnBackText?.length ? (
            <Button  onClick={handleBack} variant="outlined">
              {btnBackText}
            </Button>
          ) : null}
          <div>
          
          {isCustomScreen === true ?(<FormControlLabel control={<Checkbox
              onChange={handleChange} />} label="Test Site" />) : null}
           {!!btnCloseText?.length ? (
            <Button onClick={handleClose} style={{margin:"0 16px 0 0"}} variant="outlined">
              {btnCloseText}
            </Button>
          ) : null}
          {!!btnEditText?.length ? (
            <Button  onClick={handleEdit} style={{margin:"0 16px 0 0"}} variant="outlined">
              {btnEditText}
            </Button>
          ) : null}
           {!!btnOpenText?.length ? (
            <Button disabled={btnContinueDisable} onClick={handleContinue} variant="contained">
              {btnOpenText}
            </Button>
          ) : null}

          </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
