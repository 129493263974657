import React from "react";
import { atom, useRecoilState } from "recoil";

export const getOrganizationName = atom({
  key: "getOrganizationName",
  default: "MF",
});

const OrganizationToggle = () => {
  const [organizationName, setOrganizationName] = useRecoilState<any>(getOrganizationName);

  const handleAppNameClick = (name: string | null) => {
    setOrganizationName(name);
  };

  const buttonStyle = {
    cursor: "pointer",
    padding: "12px 24px",
    transition: "color 0.3s, transform 0.3s, box-shadow 0.3s",
    marginLeft: "20px",
    color: "#333",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    border: "none",
    backgroundColor: "transparent",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
  };

  const activeButtonStyle: any = {
    ...buttonStyle,
    color: "#009cde",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  };

  const activeUnderlineStyleCS: any = {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "2px",
    backgroundColor: "#009cde",
    transform: organizationName === "CS" ? "scaleX(1)" : "scaleX(0)",
    transformOrigin: "left",
    transition: "transform 0.3s",
    zIndex: -1,
  };
  const activeUnderlineStyleTP: any = {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "2px",
    backgroundColor: "#009cde",
    transform: organizationName === "TP" ? "scaleX(1)" : "scaleX(0)",
    transformOrigin: "left",
    transition: "transform 0.3s",
    zIndex: -1,
  };
  
  const currentUrl = window.location.href;
  const containsSites = currentUrl.includes("/sites");
  const containsCreateSitesAutomate = currentUrl.includes("/CreateSitesAutomate");

  if (containsSites === false && organizationName === null) {
    handleAppNameClick("MF");
  }

  return (
    <div>
      <button
        onClick={() => {
          handleAppNameClick("MF");
        }}
        style={organizationName === "MF" ? activeButtonStyle : buttonStyle}
      >
        Member Firm Organization
        <div style={activeUnderlineStyleCS}></div>
      </button>
      <button
        onClick={() => {
          handleAppNameClick("TP");
        }}
        style={organizationName === "TP" ? activeButtonStyle : buttonStyle}
      >
        Third Party Organizations
        <div style={activeUnderlineStyleTP}></div>
      </button>
   
     
    </div>
  );
};

export default OrganizationToggle;
