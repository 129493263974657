import React from 'react';
import { useRecoilState } from 'recoil';
import { Snackbar, Alert, AlertProps } from '@mui/material';



import { atom } from 'recoil';

export type AlertColor = 'success' | 'info' | 'warning' | 'error';

export const snackbarState = atom({
  key: 'snackbarState',
  default: { open: false, message: '', severity: '' as AlertColor },
});

const CustomSnackbar: React.FC = () => {
  const [snackbar, setSnackbar] = useRecoilState(snackbarState);

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackbar.open} autoHideDuration={3500} onClose={handleClose}>
      <Alert severity={snackbar.severity} onClose={handleClose} sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
