import { useCallback, useEffect, useState } from "react";
import { DataGrid, Column, FilterRow, HeaderFilter, LoadPanel, Scrolling, Paging } from "devextreme-react/data-grid";
import { Link } from "@mui/material";

import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { RolloverFilesList } from "../../../api-client-nswag/taxportal-client";
import { dataGridConfig } from "../../../constants";
import { calculateGridHeight } from "../../../util/Utils";
import useSnackbar from "../../../hooks/useSnackbar";
import { ModalTaxPortal } from "../ModalTaxPortal/ModalTaxPortal";
import { SnackbarMessage } from "../../../hooks/useSnackbar.types";
import { ModalGridRolloverFilesProps, RolloverFilesState } from "./ModalGridRolloverFiles.types";

import "./ModalGridRolloverFiles.scss";

const initialStateData = { pending: false, fulfilled: false, files: [] };

export const ModalGridRolloverFiles = ({ fileId, isDisplayModal, handleClose }: ModalGridRolloverFilesProps) => {
  const { showSnackbar } = useSnackbar();
  const [data, setData] = useState<RolloverFilesState>(initialStateData);

  const fetchFilesExecuted = useCallback(async (fileId: number) => {
    setData({ ...initialStateData, pending: true });
    try {
      const data = await taxPortalClientApi.taxPortal_GetRolloverFiles(fileId);
      setData({ pending: false, fulfilled: true, files: data });
      showSnackbar({ message: SnackbarMessage.ROLLOVER_FILES });
    } catch (error) {
      setData(initialStateData);
    }
  }, []);

  const openLink = (link: string) => {
    const newWindow = window.open(link, "_blank", "width=500,height=500");
    if (newWindow) {
      newWindow.focus();
    }
  };

  const cellRenderFiles = (e: RolloverFilesList[]) => {
    return (
      <div className="link-cells">
        {e.map((item: RolloverFilesList) => {
          return (
            <div key={item.fileLink} className="link-files">
              <Link onClick={() => openLink(item.fileLink)}>{item.fileName}</Link>
            </div>
          );
        })}
      </div>
    );
  };

  const cellRenderFolders = (name: string, link: string) => {
    return <Link onClick={() => openLink(link)}>{name}</Link>;
  };

  useEffect(() => {
    if (isDisplayModal) fetchFilesExecuted(fileId);
  }, [fileId]);

  return (
    <ModalTaxPortal title="Rollover files" maxWidth="xl" isDisplayModal={isDisplayModal} handleClose={handleClose}>
      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        <DataGrid
          height={calculateGridHeight(200)}
          {...dataGridConfig}
          dataSource={data.files}
          noDataText={data.pending ? "Loading..." : "No data"}
          className="hq-page__grid"
        >
          <Scrolling mode="standard" />
          <LoadPanel enabled={false} />
          <FilterRow visible={false} />
          <HeaderFilter visible={false} />
          <Paging enabled={false} />
          <Scrolling columnRenderingMode="virtual" />
          <Column alignment="left" dataField="entity" caption={"Entity"} />
          <Column
            alignment="left"
            dataField="folderName"
            caption={"iManage Location"}
            cellRender={(e) => cellRenderFolders(e.data.folderName, e.data.folderLink)}
          />
          <Column
            alignment="left"
            dataField="files"
            caption={"Files"}
            cssClass={"hq-page__grid files"}
            cellRender={(e) => cellRenderFiles(e.data.files)}
          />
        </DataGrid>
      </div>
    </ModalTaxPortal>
  );
};
