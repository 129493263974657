import { useState } from "react";

import { SnackbarMessage, useSnackbar } from ".";
import { RolloverResponse } from "../pages/Rollovers/Rollovers.types";
import { taxPortalClientApi } from "../api-client-nswag/taxportal-client-runtime";
import { NOTIFICATION_MESSAGES } from "../pages/Rollovers/Rollovers.constants";

const { DEFAULT_SCHEDULE_DAYS } = NOTIFICATION_MESSAGES;

function useRolloverRequest(siteID: number) {
  const [data, setData] = useState<RolloverResponse>({ pending: true, fulfilled: true, rollovers: [] });
  const { showSnackbar } = useSnackbar();
  const [clientID, setClientID] = useState<number>();
  const [scheduleDays, setScheduleDays] = useState<number>(DEFAULT_SCHEDULE_DAYS);

  const fetchData = async () => {
    setData({ pending: true, fulfilled: true, rollovers: [] });
    try {
      if (typeof siteID === "undefined") return;
      const { hQRolloversTracks, mdmClientId, rollOverScheduleDays } =
        await taxPortalClientApi.taxPortal_GetAllRolloverInfo(siteID!);
      setData({ pending: false, fulfilled: true, rollovers: hQRolloversTracks });
      setClientID(mdmClientId);
      setScheduleDays(rollOverScheduleDays);
    } catch (error: any) {
      setData({ pending: false, fulfilled: false, rollovers: [] });
      showSnackbar({ message: SnackbarMessage.NO_SITE, severity: "error" });
      const message = typeof siteID === "undefined" ? SnackbarMessage.NO_SITE : SnackbarMessage.ERROR;
      showSnackbar({ message: message, severity: "error" });
    }
  };

  return { data, fetchData, clientID, scheduleDays };
}

export default useRolloverRequest;
