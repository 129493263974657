import { SaveWarningProps } from "./Modal.types";
import { Modal } from "./Modal";
import useUnsavedChangesWarning from "../../../hooks/useUnsavedWarning";

const ModalUnsavedWarning = ({ when }: SaveWarningProps) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = useUnsavedChangesWarning(when);

  const handleClose = () => {
    cancelNavigation();
  };

  const handleContinue = () => {
    confirmNavigation();
  };

  return (
    <Modal
      title="Are you sure you want to leave the page?"
      btnOpenText="Leave"
      btnCloseText="Continue"
      isDisplayModal={showPrompt}
      maxWidth="sm"
      handleContinue={handleContinue}
      handleClose={handleClose}
    >
      <>
        <p>You have unsaved changes</p>
        <p>
          You can <strong>leave</strong> to discard your changes, or <strong>continue editing</strong> and save
        </p>
      </>
    </Modal>
  );
};

export default ModalUnsavedWarning;
