import { Dispatch } from "react";
import { SnackbarMessage, showSnackbarType } from "../../../hooks/useSnackbar.types";
import {
  ActionTypes,
  FETCH_SUCCESS,
  InitialSiteSettingsStateProps,
  CANCEL_EDIT_ROW,
  SAVING_ERROR,
  SAVING_SUCCESS,
  SET_EDIT_ROW_KEY,
  SHOW_LOADING,
  UPDATE_CHANGES,
} from "../SiteSettingsManager.types";
import { IChangeProps } from "devextreme-react/tree-list";
import { GetSiteSettingsDTO } from "../../../api-client-nswag/taxportal-client";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";

export const fetchData = async (
  dispatch: Dispatch<ActionTypes>,
  showSnackbar: ({ message }: showSnackbarType) => void
) => {
  dispatch({ type: SHOW_LOADING });
  try {
    const data: GetSiteSettingsDTO[] = await taxPortalClientApi.taxPortal_GetHQSiteSettings();
    dispatch({
      type: FETCH_SUCCESS,
      payload: {
        data: data || [],
      },
    });
  } catch (err) {
    dispatch({ type: SAVING_ERROR });
    showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
  }
};

export const setEditRowKey = (dispatch: Dispatch<ActionTypes>, editRowKey: number | null) => {
  dispatch({
    type: SET_EDIT_ROW_KEY,
    payload: editRowKey,
  });
};

export const updateChanges = (dispatch: Dispatch<ActionTypes>, changes: IChangeProps[]) => {
  dispatch({
    type: UPDATE_CHANGES,
    payload: changes,
  });
};

export const applyChanges = async (
  dispatch: Dispatch<ActionTypes>,
  change: IChangeProps,
  dataSchedules: InitialSiteSettingsStateProps,
  userName: string,
  showSnackbar: ({ message }: showSnackbarType) => void
) => {
  if (change && change.type) {
    dispatch({ type: SHOW_LOADING });
    try {
      const values = change.data;
      const updateRow = [
        {
          ...dataSchedules.editRow,
          ...values,
        },
      ];
      const response = await sendChanges(change, updateRow, userName);

      dispatch({
        type: SAVING_SUCCESS,
        payload: {
          change,
        },
      });
      return response;
    } catch (err: any) {
      if (err.status === 409) {
        dispatch({ type: SAVING_ERROR });
        const message = getErrorMessage(err.response);
        showSnackbar({ message: message, severity: "error" });
      } else {
        const actionType = change.type === "remove" ? CANCEL_EDIT_ROW : SAVING_ERROR;
        dispatch({ type: actionType });
        showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      }
    }
  } else {
    dispatch({ type: CANCEL_EDIT_ROW });
    return null;
  }
};

const sendChanges = async (change: IChangeProps, updateRow: GetSiteSettingsDTO[], userName: string) => {
  switch (change.type) {
    case "insert":
      return taxPortalClientApi.taxPortal_InsertUpdateSiteSettings(userName, "POST", { data: updateRow });
    case "update":
      return taxPortalClientApi.taxPortal_InsertUpdateSiteSettings(userName, "PUT", {
        data: updateRow,
        id: change.key,
      });
    case "remove":
      return taxPortalClientApi.taxPortal_InsertUpdateSiteSettings(userName, "DELETE", {
        data: updateRow,
        id: change.key,
      });
    default:
      return null;
  }
};

const getErrorMessage = (response: any) => {
  let settingNames: string[] = [];
  response.forEach((setting: any) => {
    settingNames = [...settingNames, setting.setting];
  });
  const settingNamesStr = settingNames.join(",");
  return SnackbarMessage.SITE_SETTING_EXIST.replace("%item%", settingNamesStr) as SnackbarMessage;
};
