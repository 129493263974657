import { useContext, useEffect, useRef, useState } from "react";
import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Paging,
  Selection,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { useRecoilValue } from "recoil";

import { Button, Pagination } from "@mui/material";

import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import {
  GetAllSitesDTO,
  GetSiteSettingsDTO,
  ServiceBusEnum,
  ServiceMessage,
  SyncTypeEnum,
} from "../../../api-client-nswag/taxportal-client";
import { dataGridConfig, defaultPageSize, refreshFlagAtom } from "../../../constants";
import { calculateGridHeight, convertUTCTimeToLocal } from "../../../util/Utils";
import useSnackbar from "../../../hooks/useSnackbar";
import { SnackbarMessage } from "../../../hooks/useSnackbar.types";
import { getAppName } from "../../commonComponents/AppNameToggle";
import { updateSites } from "../../../pages/UpdateScreen/UpdateSiteInfo.types";
import dxDataGrid from "devextreme/ui/data_grid";
import { isEmpty, isNull } from "lodash";

type DatagridRefType = DataGrid;

const initialStateData = {
  pending: false,
  fulfilled: false,
  sites: [],
  totalCount: 0,
};

interface SitesTableProps {
  getSelectedSettings: () => any[] & Promise<any[]>;
  selectedSettings: GetSiteSettingsDTO[];
  settingsInstance?: dxDataGrid<any, any> | null;
}

export const SitesTable = ({ getSelectedSettings, selectedSettings, settingsInstance }: SitesTableProps) => {
  const { user } = useContext(OidcIdentityContext);
  const [data, setData] = useState<updateSites>(initialStateData);
  const [selectedRows, setSelectedRows] = useState<GetAllSitesDTO[]>([]);
  const refreshFlag = useRecoilValue(refreshFlagAtom);
  const { showSnackbar } = useSnackbar();
  const dataGridRef = useRef<DatagridRefType>(null);
  const [pageNumber, setpageNumber] = useState(1);
  const appName = useRecoilValue(getAppName);
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null);
  const areRowsSelected = !selectedRows.length || !selectedSettings.length;

  const fetchSites = async () => {
    setData({
      pending: true,
      fulfilled: false,
      sites: [],
      totalCount: 0,
    });
    try {
      const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const newData = await taxPortalClientApi.taxPortal_GetAllSites(
        SyncTypeEnum.SYNC_SITE_SETTINGS,
        null,
        appName,
        startIndex,
        defaultPageSize
      );
      setData({
        pending: false,
        fulfilled: true,
        sites: newData.data,
        totalCount: newData.totalCount,
      });
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      setData(initialStateData);
    }
  };

  const onSelectionchanged = (e: any) => {
    setSelectedRows(e.selectedRowsData);
  };

  const cellRenderForDate = (e: string) => {
    return <div>{convertUTCTimeToLocal(e)}</div>;
  };

  const cellRenderForMessage = (e: any) => {
    if (e.data.message === null && e.data.status === null) {
      return null;
    }
    return <div title={e.data.message}>{e.data.status}</div>;
  };

  const sendRequest = async (eventType: ServiceBusEnum) => {
    const gridInstance = dataGridRef.current!.instance;
    try {
      const selectedSettingsToSend = getSelectedSettings().map((setting) => setting.id);
      const selectedData = gridInstance.getSelectedRowsData();
      const data = selectedData.map(
        (e: GetAllSitesDTO): ServiceMessage => ({
          siteId: e.hqSiteId,
          userName: user?.profile.preferred_username,
          eventType,
          mdmClientId: e.mdmClientId,
          settingsIds: selectedSettingsToSend,
        })
      );
      await taxPortalClientApi.taxPortal_InsertTopic(data);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    } finally {
      gridInstance.clearSelection();
      settingsInstance!.clearSelection();
    }
  };

  const handleRunClick = () => {
    showSnackbar({ message: SnackbarMessage.SITE_SETTINGS_PROGRESS });
    sendRequest(ServiceBusEnum.SYNC_SITE_SETTINGS);
  };

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: async () => {
      setSearchTerm(null);
      if (dataGridRef.current) {
        dataGridRef.current.instance.clearFilter();
      }
      fetchSites();
    },
  };

  useEffect(() => {
    if (searchTerm === null || searchTerm === undefined || searchTerm.length === 0) {
      fetchSites();
    }
  }, [refreshFlag, pageNumber, appName, searchTerm]);

  const fetchSitesBySearchTerms = async () => {
    try {
      setData({
        pending: true,
        fulfilled: false,
        sites: [],
        totalCount: 0,
      });
      const res = await taxPortalClientApi.taxPortal_GetAllSiteSearch(
        SyncTypeEnum.SYNC_SITE_SETTINGS,
        appName !== null ? appName : "PERF",
        searchTerm
      );
      if (res != null) {
        setData({
          pending: false,
          fulfilled: true,
          sites: res.data,
          totalCount: res.totalCount,
        });
      } else {
        setData({ pending: false, fulfilled: true, sites: [], totalCount: 0 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
      fetchSitesBySearchTerms();
    }
    // eslint-disable-next-line
  }, [appName, searchTerm]);

  return (
    <div style={{ marginTop: "20px" }}>
      <DataGrid
        height={calculateGridHeight(270)}
        onSelectionChanged={onSelectionchanged}
        {...dataGridConfig}
        dataSource={data.sites}
        noDataText={data.pending === true && data.fulfilled === false ? "Loading..." : "No data"}
        ref={dataGridRef}
      >
        <Selection mode="multiple" selectAllMode={"allPages"} showCheckBoxesMode={"onClick"} />
        <Scrolling mode="standard" />
        <LoadPanel enabled={false} />
        <SearchPanel
          placeholder={"Global Search..."}
          onTextChange={(e) => {
            setSearchTerm(e);
          }}
          visible
        />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        {/* <Paging enabled={true} defaultPageSize={20} /> */}
        <Scrolling columnRenderingMode="virtual" />
        <Toolbar>
          <Item location="before">
            <h4>Select the sites you need to update</h4>
          </Item>
          <Item location="after">
            <Button onClick={handleRunClick} disabled={areRowsSelected} variant="outlined">
              RUN
            </Button>
          </Item>
          <Item name="searchPanel" location="after" />
          <Item widget="dxButton" options={refreshButtonOptions} />
        </Toolbar>
        <Column width="70px" alignment="left" dataField="hqSiteId" caption={"SiteID"} />
        <Column width="70px" alignment="left" dataField="mdmClientId" caption={"MasterID"} />
        <Column width="70px" alignment="left" dataField="mdmClientId" caption={"ClientID"} />
        <Column dataField="hqClientName" caption={"SiteName"} />
        <Column width="170px" dataField="siteCreatedBy" caption={"Site Created by"} />
        <Column
          width="190px"
          cellRender={(e) => cellRenderForDate(e.data.siteCreated)}
          dataField="Site Created Date / Time"
          caption={"Site Created Date / Time"}
        />
        <Column cellRender={cellRenderForMessage} width="70px" dataField="status" caption={"Status"} />
        <Column width="170px" dataField="lastModifiedBy" caption={"Last Updated by"} />
        <Column
          width="190px"
          cellRender={(e) => cellRenderForDate(e.data.lastModified)}
          dataField="Last Update Date/Time"
          caption={"Last Update Date/Time"}
        />
      </DataGrid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "5px 0 0 0",
        }}
      >
        <Pagination
          onChange={(e: any, pageNumber: any) => {
            setpageNumber(pageNumber);
          }}
          count={Math.ceil((data.totalCount as number) / defaultPageSize)}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};
