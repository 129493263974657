import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalPropsThird } from "../Modal/Modal.types";
import "./ModalTaxPortal.scss";

export const ModalTaxPortalThree = ({
  title = "",
  firstBtnText = "",
  secondBtnText = "Cancel",
  thirdButtonText = "",
  maxWidth = "sm",
  isDisplayModal,
  handleFirstBtn,
  handleSecondBtn,
  handleTrirdBtn,
  isDisabledOne = false,
  isDisabledTwo = false,
  isDisabledThree = false,
  handleClose,
  children,
}: ModalPropsThird) => {
  return (
    <Dialog
      open={isDisplayModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={maxWidth}
      className="hq-page hq-modal cui-c_modal"
      scroll="paper"
      PaperProps={{
        style: { maxHeight: "500px", height: "auto" },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="cui-c_modal-title hq-title">
        {title}
      </DialogTitle>
      <IconButton aria-label="close" className="modal_button-cancel" onClick={handleClose}>
        <CloseIcon sx={{ fontSize: "26px" }} />
      </IconButton>
      <DialogContent className="cui-c_modal-body cui-c_modal-body-three" dividers>
        {children}
      </DialogContent>
      <DialogActions className="cui-c_modal-footer">
        <Button
          onClick={handleFirstBtn}
          variant="contained"
          className="hq_button hq_button-secondary"
          disabled={isDisabledOne}
        >
          {firstBtnText}
        </Button>
        {!!secondBtnText?.length ? (
          <Button
            onClick={handleSecondBtn}
            variant="contained"
            className="hq_button hq_button-secondary"
            disabled={isDisabledTwo}
          >
            {secondBtnText}
          </Button>
        ) : null}
        {!!thirdButtonText?.length ? (
          <Button onClick={handleTrirdBtn} variant="contained" className="hq_button" disabled={isDisabledThree}>
            {thirdButtonText}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
