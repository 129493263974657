import { IChangeProps } from "devextreme-react/tree-list";
import { GetScheduleFiles } from "../../api-client-nswag/taxportal-client";

export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const SHOW_LOADING = "SHOW_LOADING";
export const SAVING_CANCEL = "SAVING_CANCEL";
export const SAVING_ERROR = "SAVING_ERROR";
export const SAVING_SUCCESS = "SAVING_SUCCESS";
export const SET_EDIT_ROW_KEY = "SET_EDIT_ROW_KEY";
export const UPDATE_CHANGES = "UPDATE_CHANGES";
export const TAX_PORTAL = "TP";

export interface SelectOption {
  ID: string;
  Name: string;
}

export interface InitialScheduleStateProps {
  data: GetScheduleFiles[];
  changes: IChangeProps[] | [];
  editRowKey: null | number;
  editRow: GetScheduleFiles | null;
  isLoading: boolean;
  userName: string;
}

export type ActionTypes =
  | { type: typeof SHOW_LOADING }
  | { type: typeof FETCH_SUCCESS; payload: Partial<InitialScheduleStateProps> }
  | { type: typeof SAVING_ERROR }
  | { type: typeof SET_EDIT_ROW_KEY; payload: number | null }
  | { type: typeof SAVING_CANCEL }
  | { type: typeof UPDATE_CHANGES; payload: IChangeProps[] }
  | { type: typeof SAVING_SUCCESS; payload: { change: IChangeProps } };

export interface EditModeType {
  [key: string]: boolean;
}

export const constants = {
  APP: "App",
  SCHEDULE_NAME: "Schedule Name",
  TIME: "Time",
  FREQUENCY: "Frequency",
  START_EXECUTION_DATE: "Start Execution Date / Time",
  LAST_EXECUTION: "Last Execution",
  LAST_EXECUTION_DATE: "Last Execution Date / Time",
  NEXT_EXECUTION_DATE: "Next Execution Date / Time",
  LOG_DETAILS: "Log Details",
  GRID_DATE_TIME_FORMAT: "YYYY-MM-DDTHH:mm:ss.SSSSSSS",
};
