import React from "react";
import './_pageNotFound.scss';

export const PageNotFound: React.FunctionComponent = () => {
	return (
		<div className="page">
			<p>Page not found.</p>
		</div>
	)
}

export default PageNotFound;
