import { DateBox } from "devextreme-react";

import { dateCellPicker } from "../../../constants";
const { DATE_OPTION, DATE_TIME_OPTION, DATE_TIME_FORMAT, DATE_FORMAT } = dateCellPicker;

export const CellDateTimePicker = ({
  value,
  setValue,
  showTimeOptions,
  minDate,
}: {
  value: Date;
  setValue: (value: Date) => void;
  showTimeOptions: boolean;
  minDate?: Date;
}) => {
  const date = value || new Date();

  const handleConfirm = (selectedDate: Date) => {
    setValue(selectedDate);
  };

  return (
    <DateBox
      type={showTimeOptions ? DATE_TIME_OPTION : DATE_OPTION}
      value={date}
      onValueChanged={(e) => handleConfirm(e.value)}
      displayFormat={showTimeOptions ? DATE_TIME_FORMAT : DATE_FORMAT}
      useMaskBehavior
      min={minDate}
    />
  );
};
