import { Navbar } from "./components/Navbar";

function CreateSites() {
  const linkRoutes = [
    {
      path: "/sites",
      tagName: "SITES",
    },
    {
      path: "/clients",
      tagName: "CLIENTS",
    },
  ];
  return <Navbar linkRoutes={linkRoutes} />;
}
export default CreateSites;
