import { IChangeProps } from "devextreme-react/tree-list";
import { GetSiteSettingsDTO } from "../../api-client-nswag/taxportal-client";

export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const SHOW_LOADING = "SHOW_LOADING";
export const CANCEL_EDIT_ROW = "CANCEL_EDIT_ROW";
export const SAVING_ERROR = "SAVING_ERROR";
export const SAVING_SUCCESS = "SAVING_SUCCESS";
export const SET_EDIT_ROW_KEY = "SET_EDIT_ROW_KEY";
export const UPDATE_CHANGES = "UPDATE_CHANGES";
export const COL_OPTION = "allowEditing";
export const PARENT_TYPE = "dataRow";
export const SETTING = "setting";
export const enabledOnAdd = [SETTING];

export interface SelectOption {
  ID: string;
  Name: string;
}

export interface InitialSiteSettingsStateProps {
  data: GetSiteSettingsDTO[];
  changes: IChangeProps[] | [];
  editRowKey: null | number;
  editRow: GetSiteSettingsDTO | null;
  isLoading: boolean;
  userName: string;
}

export type ActionTypes =
  | { type: typeof SHOW_LOADING }
  | { type: typeof FETCH_SUCCESS; payload: Partial<InitialSiteSettingsStateProps> }
  | { type: typeof SAVING_ERROR }
  | { type: typeof SET_EDIT_ROW_KEY; payload: number | null }
  | { type: typeof CANCEL_EDIT_ROW }
  | { type: typeof UPDATE_CHANGES; payload: IChangeProps[] }
  | { type: typeof SAVING_SUCCESS; payload: { change: IChangeProps } };

export interface EditModeType {
  [key: string]: boolean;
}
