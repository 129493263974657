import React from "react";
import {NavigationMenu} from "../../navigation/NavigationBar.types";

export const navigationMenuButtonsDefaultValue = [] as NavigationMenu[];

export const SharedLayoutContext = React.createContext({
		navigationMenuButtons: navigationMenuButtonsDefaultValue,
		setNavigationMenuButtons: (_navigationMenuButtons: NavigationMenu[]) => {
		},
	}
);

export default SharedLayoutContext;
