import { Dispatch, SetStateAction } from "react";
import { FilesInspection, MoveIManageFiles } from "../../../api-client-nswag/taxportal-client";

export interface ModalCopyProps {
  filesInspection: FilesInspection;
  setFilesInspection: Dispatch<SetStateAction<FilesInspection>>;
  isDisplayModal: boolean;
  setIsDisplayModal: Dispatch<SetStateAction<boolean>>;
  itemEdited: Array<number>;
  setItemEdited: Dispatch<SetStateAction<Array<number>>>;
  onHandleService: (data: MoveIManageFiles[]) => Promise<void>;
  handleCloseModal: () => void;
  isBlurEnabled: boolean;
  setIsBlurEnabled: Dispatch<SetStateAction<boolean>>;
}

export enum COLORS {
  ACTIVE = "primary",
  DEFAULT = "inherit",
}
