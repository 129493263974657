import { atom } from "recoil";
import { updateSites } from "./UpdateSiteInfo.types";

export const SiteUpdate = atom<updateSites>({
    key: "SiteUpdate",
    default: { pending: false, fulfilled: false, sites: [], totalCount:0 },
  });
  export const SynCEMHQ = atom({
    key: "SynCEMHQ",
    default: { pending: false, fulfilled: false, sync: [] },
  });
  