import React from "react";
import { envProperties } from "../../util/EnvironmentProperties";

const SignOut = () => {
  const handleRedirect = () => {
    if (window.self !== window.top) {
      if (window.top) {
        window.top.location.href = `${envProperties.highQLoginUrl}`;
      } else {
        console.error("Cannot access window.top");
      }
    } else {
      window.location.href = window.location.origin;
    }
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <span style={{ cursor: "pointer" }} onClick={handleRedirect}>
          Click here to login again
        </span> */}
        <span>
        Your session has expired. Please login again.
        </span>
      </div>
    </>
  );
};

export default SignOut;
