import { RefObject, useEffect, useState } from "react";

const useContainerDimensions = (elementRef: RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: elementRef.current!.offsetWidth!,
      height: elementRef.current!.offsetHeight!,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (elementRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [elementRef]);

  return dimensions;
};

export default useContainerDimensions;
