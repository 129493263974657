import { ServiceBusEnum } from "../../api-client-nswag/taxportal-client";

export const IR_ROLLOVERS = [typeof ServiceBusEnum.IR_ROLLOVER];
export const FILES_ROLLOVERS = [typeof ServiceBusEnum.FILES_ROLLOVER];

export const FILES_TYPE = "Files";

export const SERVICE_OPTIONS = {
  SCHEDULE: "schedule",
  INSER_TOPIC: "insert topic",
  CANCEL_SCHEDULE: "cancel",
};

export const PAGE_CAPTIONS = {
  ROLLOVER_PAGE_TITLE: "Make a selection to begin rollover",
  MODAL_TITLE: "Are you sure you want to continue?",
  GRID_TITLE: "Rollover History",
  USER: "User",
  TYPE: "Type",
  ROLOVER_REQUESTED_DATE: "Rollover Requested Date",
  ROLOVER_SCHEDULED_DATE: "Scheduled Date",
  ROLLOVER_DATE: "Rollover Date",
  STATUS: "Status",
  STATUS_DETAILS: "Status Details",
  EXECUTION_DETAILS: "Execution Details",
  FILES: "Files",
  RETRY: "Retry",
  CANCEL_SCHEDULED: "Cancel Scheduled",
  CANCELED_BY: "Canceled By",
  CANCELED_DATE: "Canceled Date",
  NOTIFICATION_SENT: "Notifications Sent",
  FIRST_NOTIFICATION_SENT: "First Notification Sent",
  SECOND_NOTIFICATION_SENT: "Second Notification Sent",
};

export const ROLLOVER_OPTIONS = {
  INFORMATION_REQUEST: "Information Request",
  FILES: "Files",
  BOTH: "Both",
};

export const ROLLOVER_STATUSES = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

export const NOTIFICATION_MESSAGES = {
  OPENING_MESSAGE: "If you continue, rollover is not reversible.",
  INFORMATION_REQUEST_MSG: " The Information Request list will be rolled forward.",
  FILES_MSG_1: " Files will be removed from Tax Portal in %days% days.",
  FILES_MSG_2: " Clients will be notified via email if you continue.",
  CLOSING_MESSAGE: "Select Continue to proceed or Cancel to close.",
  DEFAULT_SCHEDULE_DAYS: 1,
};
