import React, { useEffect, useState } from "react";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import {
  getSiteCreationResponse,
  getSiteExistResponse,
  getTaxYearResponseForCS,
} from "../SitesWithLegalEntities";
import { format } from "date-fns";

import DateBox from "devextreme-react/date-box";

const isSiteSiteExistSelector = selector({
  key: "getSiteCreationResponse",
  get: ({ get }) => {
    const text = get(getSiteCreationResponse);
    return text;
  },
});
const getSiteExistResponseSelector = selector({
  key: "getSiteExistResponse",
  get: ({ get }) => {
    const text = get(getSiteExistResponse);
    return text;
  },
});
const dateLabel = { "aria-label": "Date" };

function SelectDates(props: any) {
  const now = new Date();
  const [startValue, setStartValue] = useState<any>();
  const [endValue, setEndValue] = useState<any>();
  const [value, setValue] = useState<any>();
  const [taxYearForCS, setTaxYearForCS] = useRecoilState<any>(getTaxYearResponseForCS);

  useEffect(() => {
    setValue(startValue);
  }, [startValue]);

  useEffect(() => {
    if (endValue !== undefined && endValue !== null) {
      const formattedYear = format(new Date(endValue), 'yyyy');
      setTaxYearForCS(formattedYear);
      props.setBtnContinueDisable(false);
    }
  }, [endValue]);

  useEffect(() => {
    if(taxYearForCS === null) props.setBtnContinueDisable(true);
  },[])
  
  useEffect(() => {
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return format(date, "yyyy-MM-dd");
    };

    const formattedStartDate = (startValue !== undefined) ? formatDate(startValue) : null;
    const formattedEndDate = (endValue !== undefined) ? formatDate(endValue) : null;
    props.setSiteDates({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  }, [startValue, endValue]);

  const currentYear = new Date().getFullYear();
  const minDate = new Date(currentYear - 10, 0, 1); 
  const maxDate = new Date(currentYear + 15, 11, 31); 
  return (
    <>
      <div>Instruction:</div>
      <div>
        {
          "Select the start and end dates for your client. Refer to site creation form for these details or reach out to Engagement team."
        }
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          margin: "16px 0 0 0",
        }}
      >
 
        <label style={{ display: "flex", alignItems: "center" }}>
          Start Date
        </label>

        <DateBox
          onValueChanged={(newValue) => setStartValue(newValue.value)}
          defaultValue={undefined}
          inputAttr={dateLabel}
          type="date"
          min={minDate}
          max={maxDate}
        />
        <label style={{ display: "flex", alignItems: "center" }}>
          End Date
        </label>
        <DateBox
          onValueChanged={(newValue) => setEndValue(newValue.value)}
          defaultValue={undefined}
          inputAttr={dateLabel}
          type="date" 
          min={startValue}
          max={maxDate}
        />
      </div>
    </>
  );
}

export default SelectDates;
