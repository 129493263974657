import Select from "react-select";

const SiteNameDropdown = (props: any) => {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      width: 300,
      borderColor: state.isFocused ? "#009cde" : "#ccc",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#009cde" : "white",
      color: state.isFocused ? "white" : "black",
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: "300px",
    })
  };
console.log(props,"props")
  return (
      <Select
        styles={customStyles} 
        options={props.options}
        onChange={props.handleOnChange}
        placeholder="Select a Site..."
        noOptionsMessage={() =>
          props.siteName.pending ? "Loading..." : "No Data"
        }
        isSearchable
        value={props.selectedOption}
      />
  );
};

export default SiteNameDropdown;
