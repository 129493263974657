import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

import { signalRMethods } from "../constants";

export let hubConnection: HubConnection;

export const CreateHubConnection = (
  url: string,
  userName: string,
  accessToken: string
): HubConnection => {

  const hubOptions = {
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: () => accessToken,
    skipNegotiation: true,
    transportOptions: {
      websocket: {
        headers: {
          Authorization: `Bearer${accessToken}`,
        },
      },
    },
  };


  hubConnection = new HubConnectionBuilder()
    .withUrl(`${url}?userName=${userName}`)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext:any) => {
        //use defaults, then 30 seconds forever
        const maxRetry = 3
        if(retryContext.previousRetryCount < maxRetry){
          const retryIntervals = [0, 2000, 10000, 30000];
          return retryIntervals[
            Math.min(retryContext.previousRetryCount, retryIntervals.length - 1)
          ];
        }
        return null
      },
    })
    .configureLogging(LogLevel.Information)
    .build();
  return hubConnection;
};

const Start = async (hubConnection: HubConnection): Promise<void> => {
  try {
    await hubConnection.start();
    hubConnection.serverTimeoutInMilliseconds = 1000 * 60; // 1 minute
    hubConnection.keepAliveIntervalInMilliseconds = 1000 * 20; // 20 seconds
  } catch (err) {
    console.log(err);
  }
};


const SetUpHubCallbacks = (hubConnection: HubConnection, showSnackbar:any): void => {
  try {
   
    hubConnection.on(signalRMethods.success, (Message: string) => {
      console.log("success : " + Message);
      showSnackbar(Message, "success");
    });

    hubConnection.on(signalRMethods.error, (Message: string) => {
      console.log("error : " + Message);
      showSnackbar(Message, "error");
    });

    hubConnection.on(signalRMethods.info, (Message: string) => {
      console.log("info : " + Message);
      showSnackbar(Message, "info");
    });

    hubConnection.on(signalRMethods.ir_success, (Message: string) => {
      console.log("IR Report : " + Message);
      //showSnackbar(Message, "success");
          });

    hubConnection.on(signalRMethods.ps_success, (Message: string) => {
      console.log("PS Report : " + Message);
     // showSnackbar(Message, "success");
    });

  } catch (err) {
    console.log(err);
  }
};

export const StartHub = (hubConnection: HubConnection, showSnackbar:any): void => {  
  SetUpHubCallbacks(hubConnection, showSnackbar);
  Start(hubConnection); 
};
