import dxDataGrid from "devextreme/ui/data_grid";
import CheckBox from "devextreme/ui/check_box";
import { SelectedDetailsData, constants } from "../UpgradeSitesGrids.types";
const { DISABLED_CHECK } = constants;

export const upsertData = <T>(arrData: T[], newData: T, key: keyof T, key2?: keyof T): T[] => {
  const foundIndex = arrData.findIndex((change) => {
    const k1 = key2 ? `${change[key]}${change[key2]}` : change[key];
    const k2 = key2 ? `${newData[key]}${newData[key2]}` : newData[key];
    return k1 === k2;
  });
  if (foundIndex !== -1) {
    return arrData.map((change, index) => (index === foundIndex ? newData : change));
  } else {
    return [...arrData, newData];
  }
};

export const selectParent = (component: dxDataGrid<any, any>, key: number | string, doSelect = false) => {
  const selected = component.isRowSelected(key);
  if (doSelect && !selected) {
    component.selectRows([key], true);
  } else if (!doSelect && selected) {
    component.deselectRows([key]);
  }
};

export const areObjectsEqual = (obj1: SelectedDetailsData, obj2: SelectedDetailsData): boolean => {
  if (obj1.comparisonId !== obj2.comparisonId || obj1.changeId !== obj2.changeId) {
    return false;
  }
  if (obj1.details.length !== obj2.details.length) {
    return false;
  }
  for (let i = 0; i < obj1.details.length; i++) {
    if (obj1.details[i] !== obj2.details[i]) {
      return false;
    }
  }
  return true;
};

export const disableCheckboxes = (datagridRef: dxDataGrid<any, any>, gridKeysToDisable: string[]) => {
  for (const gridKey of gridKeysToDisable) {
    const rowIndex = datagridRef.getRowIndexByKey(gridKey);
    if (rowIndex !== -1) {
      const cellElement = datagridRef.getCellElement(rowIndex, 0) as HTMLElement;
      cellElement.style.pointerEvents = "none";
      disableCheckbox(cellElement);
    }
  }
};

export const disableCheckbox = (cellElement: HTMLElement) => {
  const checkBoxEl = cellElement.querySelector(".dx-checkbox") as HTMLElement;
  checkBoxEl.style.background = DISABLED_CHECK;
  const checkBox = CheckBox.getInstance(checkBoxEl);
  checkBox.option("disabled", true);
  checkBox.option("readOnly", true);
};
