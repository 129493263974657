import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

const CustomizeOrgName = (props: any) => {
  const [text, setText] = useState("");


  const onChange = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[ A-Za-z0-9_.-]*$/;
    if (regex.test(inputValue)) {
      setText(inputValue);
      props.setOrgName(inputValue);
    }
  };

  return (
    <>
      <div>Instructions...</div>
      <div>
        <ul>
          <li>{"No special characters can be used except (Hyphen (-), Dot(.) and Underscore ( _ ))"}</li>
          <li>Duplicate organization names are not allowed.</li>
          <li>{"Organization name should not be exceeded 128 characters"}</li>
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          margin: "16px 0 0 0",
        }}
      >
        <TextField
          inputProps={{ maxLength: 128 }}
          id="outlined-basic"
          variant="outlined"
          style={{ width: "30%" }}
          onChange={onChange}
          value={text}
        />
        <div style={{ fontWeight: "bold", color: "#009CDE", marginLeft: "16px", fontSize: "14px" }}>
          {128 - text.length} characters left
        </div>
      </div>
      {props.flag === true ? <div style={{ color: "red", fontSize: "14px" }}>{props.message}</div> : null}
    </>
  );
};

export default CustomizeOrgName;
