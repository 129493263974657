import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { ToolTipProps } from "./toolTip.types";

const TooltipWrapper = ({
  condition,
  toolTipPlacement,
  show,
  title,
  index,
  tooltipOpen,
  handleTooltipToggle,
  children,
}: ToolTipProps) => {
  const wrapper = () => {
    return (
      <Tooltip
        title={title}
        arrow
        placement={toolTipPlacement}
        open={!show && tooltipOpen[index]}
        onClose={() => handleTooltipToggle(index, false)}
        onOpen={() => handleTooltipToggle(index, true)} // testing: remove on production
      >
        {children}
      </Tooltip>
    );
  };

  return condition ? wrapper() : children;
};

export default TooltipWrapper;
