import { RolloverStatusDetails } from "../../../api-client-nswag/taxportal-client";

export interface ModalRolloverStatusDetailsProps {
  rolloverId: number;
  isDisplayModal: boolean;
  handleClose: any;
}

export interface RolloverStatusDetailsState {
  pending: boolean;
  fulfilled: boolean;
  details: RolloverStatusDetails[];
}

export const EXECUTION_DETAILS_TITLE = "Execution details";

export const titles = {
  ENTITY_NAME: "Entity Name",
  STEP: "Step",
  STATUS: "Status",
  STATUS_MESSAGE: "Status Message",
};
