import { useCallback, useEffect, useState } from "react";

import { DataGrid } from "devextreme-react";
import { Column, FilterRow, HeaderFilter, LoadPanel, Paging, Scrolling } from "devextreme-react/data-grid";

import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";

import { calculateGridHeight } from "../../../util/Utils";
import { dataGridConfig } from "../../../constants";

import { ModalTaxPortal } from "../ModalTaxPortal/ModalTaxPortal";
import {
  EXECUTION_DETAILS_TITLE,
  ModalRolloverStatusDetailsProps,
  RolloverStatusDetailsState,
  titles,
} from "./ModalRolloverStatusDetails.types";

import "./ModalRolloverStatusDetails.scss";

const initialStateData = { pending: false, fulfilled: false, details: [] };
const { ENTITY_NAME, STEP, STATUS, STATUS_MESSAGE } = titles;

export const ModalRolloverStatusDetails = ({
  rolloverId,
  isDisplayModal,
  handleClose,
}: ModalRolloverStatusDetailsProps) => {
  const [data, setData] = useState<RolloverStatusDetailsState>(initialStateData);

  const fetchFilesExecuted = useCallback(async (rolloverId: number) => {
    setData({ ...initialStateData, pending: true });
    try {
      const { rolloverinformationList } = await taxPortalClientApi.taxPortal_GetRollOverInformation(rolloverId);
      setData({ pending: false, fulfilled: true, details: rolloverinformationList });
    } catch (error) {
      setData(initialStateData);
    }
  }, []);

  useEffect(() => {
    if (isDisplayModal) fetchFilesExecuted(rolloverId);
  }, [rolloverId]);

  return (
    <>
      <ModalTaxPortal
        title={EXECUTION_DETAILS_TITLE}
        maxWidth="xl"
        isDisplayModal={isDisplayModal}
        handleClose={handleClose}
      >
        <DataGrid
          height={calculateGridHeight(270)}
          {...dataGridConfig}
          dataSource={data.details}
          noDataText={data.pending ? "Loading..." : "No data"}
          wordWrapEnabled={true}
        >
          <Scrolling mode="standard" />
          <LoadPanel enabled={false} />
          <FilterRow visible />
          <HeaderFilter visible />
          <Paging enabled defaultPageSize={20} />
          <Scrolling columnRenderingMode="virtual" />
          <Column dataField="entityName" caption={ENTITY_NAME} minWidth={100} alignment="left" />
          <Column dataField="step" caption={STEP} minWidth={100} alignment="left" />
          <Column dataField="status" caption={STATUS} minWidth={90} width={90} alignment="left" />
          <Column dataField="statusMessage" caption={STATUS_MESSAGE} minWidth={100} alignment="left" />
        </DataGrid>
      </ModalTaxPortal>
    </>
  );
};
