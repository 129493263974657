import React, { useEffect, useState } from 'react'
import {
    DataGrid,
    Column,
    FilterRow,
    HeaderFilter,
    LoadPanel,
    Scrolling,
    SearchPanel,
    Paging,
    Selection,
    Item,
    Toolbar,
  } from "devextreme-react/data-grid";
import { GetSiteHistoryDTO } from '../../../api-client-nswag/taxportal-client';
import { Button, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { dataGridConfig } from '../../../constants';
import { calculateGridHeight, convertUTCTimeToLocal } from '../../../util/Utils';
import { useRecoilState } from "recoil";
import { atom } from "recoil";
import { taxPortalClientApi } from '../../../api-client-nswag/taxportal-client-runtime';

interface  siteHistories{
  pending:boolean
  data: GetSiteHistoryDTO[]
}
interface siteHistoryTypes{
  selectedRowData :{
  name: string
  hqSiteId: number
  }
}

export const SiteHistory = (props: siteHistoryTypes) => {
  const [data, setData] = useState <siteHistories>( { pending: true, data: [] });
    useEffect(()=>{ 
      try{
      const fetchData = async() =>{
        setData({ pending: true,  data: [] });
        const data = await taxPortalClientApi.taxPortal_GetSiteHistory(props.selectedRowData.hqSiteId, props.selectedRowData.name);
        console.log(data,"data")
        setData({ pending: false,  data: data });
      }
    fetchData();
  }
  catch(error){}
    },[props.selectedRowData.name, props.selectedRowData.hqSiteId])
    const cellRender =(e: any) =>{
      return <div>{props.selectedRowData.name}</div>
    }
   
    const datetime = (e: any) => {
      if (e.data.created === null) {
        return null;
      } else {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <div>{convertUTCTimeToLocal(e.data.updateDate)}</div>
            </div>
          </div>
        );
      }
    };
  return (
    <div> 
    <DataGrid
    height={calculateGridHeight(270)}
    {...dataGridConfig}
    dataSource={data?.data}
     noDataText={data?.pending ? "Loading..." : "No data"}
    allowColumnResizing={true}
  >
    <Scrolling mode="standard" />
    <LoadPanel enabled={false} />
    <SearchPanel visible />
    <FilterRow visible={true} />
    <HeaderFilter visible={true} />
    <Paging enabled={true} defaultPageSize={20} />
    <Scrolling columnRenderingMode="virtual" />
    <Column width="50px" alignment="left" dataField="hqSiteId" caption={"Site ID"} />
    <Column width="50px" alignment="left" dataField="mdmClientId" caption={"Client Master ID"} />
    <Column width="170px" alignment="left" dataField="hqClientName" cellRender={cellRender} caption={"Site Name"} />
    <Column dataField="message" caption={"Action"} />
    <Column width="170px" dataField="updateBy" caption={"Modified by"} />
    <Column width="170px" dataField="updateDate" cellRender={datetime} caption={"Date & Time"} />
  </DataGrid></div>
  )
}
