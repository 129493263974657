import React from "react";
import { Container, Navbar as NavbarBs } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { NavbarProps } from "./Navbar.types";
import "../style.css";

export const Navbar: React.FC<{ linkRoutes: NavbarProps[] }> = ({ linkRoutes }) => {
  return (
    <NavbarBs sticky="top" style={{ backgroundColor: "white", paddingBottom: "16px", marginTop: "25px" }}>
      <Container>
        {linkRoutes &&
          linkRoutes.map(({ path, tagName }: NavbarProps) => (
            <NavLink to={path} className="nav1">
              {tagName}
            </NavLink>
          ))}
      </Container>
    </NavbarBs>
  );
};
