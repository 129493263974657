import axios from "axios";
import { env } from "@rsmus/react-env-image";
import { envProperties } from "../util/EnvironmentProperties";
import { getAccessToken } from "@rsmus/react-auth";

const baseApi = envProperties.CemBaseUrl;

export class ExternalApiClient {
  async getCEMPermissions() {
    try {
      const token = await getAccessToken();
      const response = await axios.get(
        `${baseApi}v3/authorization/User?$filter=ApplicationId eq '${envProperties.authentication.clientId}'`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch user data");
    }
  }

  async getCEMClients(userId: any) {
    try {
      const token = await getAccessToken();
      const response = await axios.get(`${baseApi}v3/users/${userId}/assignments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch user data");
    }
  }
}
