import React, { useEffect, useState } from "react";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import { getTaxYearResponse } from "../SitesWithLegalEntities";
import { format } from "date-fns";

import DateBox from "devextreme-react/date-box";
const dateLabel = { "aria-label": "Date" };

function SelectTaxYear(props: any) {
  const [taxYear, setTaxYear] = useRecoilState<any>(getTaxYearResponse);

  useEffect(() => {
    if(taxYear === null) props.setBtnContinueDisable(true);
  },[])

  const handleYear = (value: any) => {
    if (value !== null && value !== undefined) {
      const formattedYear = format(new Date(value), 'yyyy');
      setTaxYear(formattedYear);
      props.setBtnContinueDisable(false);
    }
  };
  const currentYear = new Date().getFullYear();
  const minDate = new Date(currentYear - 10, 0, 1); 
  const maxDate = new Date(currentYear + 15, 11, 31); 
  return (
    <>
      <div>Instruction:</div>
      <div style={{ color: "#009cde" }}>
        {"Select the Tax Year for your client."}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          margin: "16px 0 0 0",
        }}
      >
        <label style={{ display: "flex", alignItems: "center" }}>
          Tax Year
        </label>

        <DateBox
          onValueChanged={(newValue) => handleYear(newValue.value)}
          defaultValue={undefined}
          inputAttr={dateLabel}
          type="date"
          min={minDate}
          max={maxDate}
        />
      </div>
    </>
  );
}

export default SelectTaxYear;
